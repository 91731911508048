import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  format,
} from 'date-fns';

export const canStartCourse = (course, status) => {
  let result = {};
  if (!status?.updated) {
    result = {
      nextStartDate: null,
      canView: true,
    };
  } else {
    if (course?.tryAgainSettings === 'any') {
      result = {
        nextStartDate: null,
        canView: true,
      };
    } else if (course?.tryAgainSettings === 'year') {
      if (differenceInMonths(new Date(), new Date(status.updated)) >= 12) {
        result = {
          nextStartDate: null,
          canView: true,
        };
      } else {
        result = {
          nextStartDate: format(
            add(new Date(status.updated), { months: 12 }),
            'dd.MM.yyyy',
          ),
          canView: false,
        };
      }
    } else if (course?.tryAgainSettings === 'month') {
      if (differenceInDays(new Date(), new Date(status.updated)) >= 30) {
        result = {
          nextStartDate: null,
          canView: true,
        };
      } else {
        result = {
          nextStartDate: format(
            add(new Date(status.updated), { days: 30 }),
            'dd.MM.yyyy',
          ),
          canView: false,
        };
      }
    } else if (course?.tryAgainSettings === 'week') {
      if (differenceInDays(new Date(), new Date(status.updated)) >= 7) {
        result = {
          nextStartDate: null,
          canView: true,
        };
      } else {
        result = {
          nextStartDate: format(
            add(new Date(status.updated), { days: 7 }),
            'dd.MM.yyyy',
          ),
          canView: false,
        };
      }
    } else if (course?.tryAgainSettings === 'day') {
      if (differenceInHours(new Date(), new Date(status.updated)) >= 24) {
        result = {
          nextStartDate: null,
          canView: true,
        };
      } else {
        result = {
          nextStartDate: format(
            add(new Date(status.updated), { hours: 24 }),
            'dd.MM.yyyy',
          ),
          canView: false,
        };
      }
    }
  }

  return result;
};
