// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainHeader_MainHeader__YaneG {\n  max-width: 1440px;\n  display: block;\n  width: 100%;\n  margin: auto;\n}\n.MainHeader_MainHeader__Breadcrumbs__BxfkH {\n  padding: 10px 50px;\n  border-bottom: solid 1px rgba(0, 65, 102, 0.2);\n}\n\n@media only screen and (max-width: 480px) {\n  .MainHeader_Header__Menu__jLics {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Default/MainHeader/MainHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AACJ;AAAI;EACI,kBAAA;EACA,8CAAA;AAER;;AACA;EACI;IACI,aAAA;EAEN;AACF","sourcesContent":[".MainHeader {\n    max-width: 1440px;\n    display: block;\n    width: 100%;\n    margin: auto;\n    &__Breadcrumbs {\n        padding: 10px 50px;\n        border-bottom: solid 1px rgba(0, 65, 102, 0.2);\n    }\n}\n@media only screen and (max-width: 480px) {\n    .Header__Menu {\n        display: none;   \n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainHeader": "MainHeader_MainHeader__YaneG",
	"MainHeader__Breadcrumbs": "MainHeader_MainHeader__Breadcrumbs__BxfkH",
	"Header__Menu": "MainHeader_Header__Menu__jLics"
};
export default ___CSS_LOADER_EXPORT___;
