import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React from 'react';

import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { OneServiceContent } from '../OneServiceContent/OneServiceContent';
import { OneServiceProgressBar } from '../OneServiceProgressBar/OneServiceProgressBar';
import { OneServiceSidebar } from '../OneServiceSidebar/OneServiceSidebar';

export const OneServiceGrid = ({ service }) => {
  return (
    <BlockContainer>
      <Grid cols="12" gap="3xl">
        <GridItem col="12">
          <Text as="h2" size="4xl">
            {service?.title}
          </Text>
        </GridItem>
      </Grid>
      <Grid cols="12">
        <GridItem
          col="7"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 12,
            },
            l: {
              col: 7,
            },
          }}
        >
          <OneServiceProgressBar service={service} />
          <OneServiceContent service={service} />
        </GridItem>
        <GridItem
          col="1"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 12,
            },
            l: {
              col: 1,
            },
          }}
        ></GridItem>
        {/* <GridItem
          col="4"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 12,
            },
            l: {
              col: 4,
            },
          }}
        >
          {<OneServiceSidebar service={service} />}
        </GridItem> */}
      </Grid>
    </BlockContainer>
  );
};
