import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CallbackForm } from '../CallbackForm/CallbackForm';
import { Text } from '@consta/uikit/Text';
import { Card, CardContent } from '@mui/material';
import { AlertsList } from '../Default/AlertsList/AlertsList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const FeedbackModal = ({ isOpen, closeHandler, openAlertHandler }) => {
  return (
    <Modal
      open={isOpen}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
    >
      <Card sx={{ width: '500px', height: '430px', border: 'none' }}>
        <CardContent sx={{ border: 'none' }}>
          <Text as="h6" size="s" style={{ paddingTop: 0, marginTop: 0 }}>
            Обратная связь
          </Text>

          <CallbackForm
            showCloseButton={true}
            handleClose={(data) => closeHandler(data)}
            openAlertHandler={(data) => openAlertHandler()}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};
