import { Grid, GridItem } from '@consta/uikit/Grid';
import React from 'react';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { ServiceItem } from '../ServiceItem/ServiceItem';
export const ServicesGrid = ({ services }) => {
  return (
    <BlockContainer>
      <Grid cols="12" gap="3xl">
        {services?.length
          ? services?.map((service) => (
              <GridItem
                col="3"
                breakpoints={{
                  xs: {
                    col: 12,
                  },
                  s: {
                    col: 6,
                  },
                  l: {
                    col: 3,
                  },
                }}
              >
                <ServiceItem service={service} />
              </GridItem>
            ))
          : 'Материалы пока не добавлены'}
      </Grid>
    </BlockContainer>
  );
};
