import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/alert.store';

const defaultResponseParser = (response) => response;

export const useApi = (axiosMethod, responseParser = defaultResponseParser) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const sendRequest = useCallback(
    async (...params) => {
      try {
        setLoading(true);
        setError(null);
        const response = await axiosMethod(...params);
        const result = responseParser(response);
        setData(result);
        return result;
      } catch (err) {
        setError(err);

        dispatch(setAlert(err));
        return err;
      } finally {
        setLoading(false);
      }
    },
    [axiosMethod, responseParser],
  );

  return { data, error, loading, sendRequest };
};
