import React, { useEffect, useState } from 'react';
import { IconFavorite } from '@consta/uikit/IconFavorite';
import { Link } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';
import styles from './MaterialList.module.scss';
import { getAllCourseMaterials } from '../../api/MaterialApi';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { canStartCourse } from '../../utils/canStartCourse';

export const MaterialSmallList = ({ course, status }) => {
  const [checkResult, setCheckResult] = useState({});

  useEffect(() => {
    setCheckResult(canStartCourse(course, status));
  }, [course, status]);
  return (
    <div className={styles.MaterialList}>
      <Text className={styles.MaterialList__TitleSmall}>Содержание курса:</Text>
      <ul>
        {course?.materials?.map((material) => (
          <li key={material?.id}>
            {checkResult?.canView && !checkResult?.nextDateStart ? (
              <Link
                style={{ display: 'flex', alignItems: 'center' }}
                to={URLS.COURSES_PAGE + '/' + course.id + '/' + material?.id}
              >
                <IconFavorite style={{ width: '14px' }} />
                <span
                  style={{ width: 'calc(100% - 14px)' }}
                  dangerouslySetInnerHTML={{
                    __html: material?.title,
                  }}
                ></span>
              </Link>
            ) : (
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <IconFavorite style={{ width: '14px' }} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: material?.title,
                  }}
                ></span>
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
