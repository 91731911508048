import { Button, TextField } from '@mui/material';

import React, { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { sendNotification } from '../../api/NotificationApi';

export const CallbackForm = ({
  showCloseButton,
  handleClose,
  openAlertHandler,
}) => {
  const [form, setForm] = useState({});
  const sendNotificationApi = useApi(sendNotification);

  const sendNotificationHandler = async () => {
    await sendNotificationApi.sendRequest({
      subject: form.title,
      html: form.message,
    });
    setForm({});
    openAlertHandler();
    handleClose();
  };
  return (
    <>
      <TextField
        placeholder="Тема обращения"
        label="Тема обращения"
        fullWidth
        value={form?.title}
        style={{ marginBottom: '10px' }}
        onChange={({ target: { value } }) => setForm({ ...form, title: value })}
      />
      <TextField
        placeholder="Сообщение"
        label="Сообщение"
        multiline
        minRows={4}
        maxRows={4}
        fullWidth
        rows={7}
        cols={50}
        type="textarea"
        value={form?.message}
        style={{ marginBottom: '10px' }}
        onChange={({ target: { value } }) =>
          setForm({ ...form, message: value })
        }
      />
      <Button
        variant="outlined"
        sx={{ marginTop: '20px', marginRight: '10px' }}
        onClick={() => sendNotificationHandler()}
      >
        Отправить обращение
      </Button>
      {showCloseButton && (
        <Button
          variant="outlined"
          color="error"
          sx={{ marginTop: '20px', marginRight: '10px' }}
          onClick={() => handleClose(true)}
        >
          Закрыть
        </Button>
      )}
    </>
  );
};
