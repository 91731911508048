// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CoursesPageSidebar_CoursesPageSidebar__SearchField__tSEwT {\n  border-bottom: solid 1px rgba(0, 66, 105, 0.2784313725) !important;\n}\n.CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb {\n  padding-left: 0;\n  padding-bottom: 5px;\n}\n.CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb li {\n  list-style: none;\n  margin-bottom: 18px;\n}\n.CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb li a {\n  color: #002033;\n  text-decoration: none;\n  display: flex;\n}\n.CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb li a:hover span {\n  color: #0174DE !important;\n}\n.CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb li a span {\n  display: block;\n}\n.CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb li a span:first-child {\n  margin-right: 9px;\n}", "",{"version":3,"sources":["webpack://./src/components/CoursePageSidebar/CoursesPageSidebar.module.scss"],"names":[],"mappings":"AACI;EACI,kEAAA;AAAR;AAEI;EACI,eAAA;EACA,mBAAA;AAAR;AACQ;EACI,gBAAA;EACA,mBAAA;AACZ;AAAY;EACI,cAAA;EACA,qBAAA;EACA,aAAA;AAEhB;AAAoB;EACI,yBAAA;AAExB;AACgB;EACI,cAAA;AACpB;AAAoB;EACI,iBAAA;AAExB","sourcesContent":[".CoursesPageSidebar {\n    &__SearchField {\n        border-bottom: solid 1px #00426947 !important;\n    }\n    &__CategoriesList {\n        padding-left: 0;\n        padding-bottom: 5px;\n        li {\n            list-style: none;\n            margin-bottom: 18px;\n            a {\n                color: #002033;\n                text-decoration: none;\n                display: flex;\n                &:hover {\n                    span {\n                        color: #0174DE !important;\n                    }\n                }\n                span {\n                    display: block;\n                    &:first-child {\n                        margin-right: 9px;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CoursesPageSidebar__SearchField": "CoursesPageSidebar_CoursesPageSidebar__SearchField__tSEwT",
	"CoursesPageSidebar__CategoriesList": "CoursesPageSidebar_CoursesPageSidebar__CategoriesList__NWdtb"
};
export default ___CSS_LOADER_EXPORT___;
