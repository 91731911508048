// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaterialNavigationList_MaterialNavigationList__x9gl9 {\n  max-height: 450px;\n  overflow: auto;\n  margin-bottom: 24px;\n}", "",{"version":3,"sources":["webpack://./src/components/MaterialNavigationList/MaterialNavigationList.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;EACA,mBAAA;AACJ","sourcesContent":[".MaterialNavigationList {\n    max-height: 450px;\n    overflow: auto;\n    margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MaterialNavigationList": "MaterialNavigationList_MaterialNavigationList__x9gl9"
};
export default ___CSS_LOADER_EXPORT___;
