import React, { useState } from 'react';
import { Card } from '@consta/uikit/Card';
import styles from './MainFooter.module.scss';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { H3 } from '../Title/H3';
import { Text } from '@consta/uikit/Text';
import { FooterCallBackForm } from '../Form/FooterCallBackForm';
import { Button } from '@consta/uikit/Button';
import { BlockContainer } from '../BlockContainer/BlockContainer';
import { Link } from 'react-router-dom';
import { FeedbackModal } from '../../FeedbackModal/FeedbackModal';
import { AlertsList } from '../AlertsList/AlertsList';

export const MainFooter = ({ settings }) => {
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [alerts, setAlerts] = useState([]);
  return (
    <Card
      as="footer"
      form="square"
      shadow={false}
      horizontalSpace="4xl"
      verticalSpace="5xl"
      className={styles.MainFooter}
    >
      <BlockContainer>
        <Grid cols={3}>
          <GridItem
            col={2}
            breakpoints={{
              xs: {
                col: 3,
              },
              m: {
                col: 2,
              },
            }}
          >
            <H3 className={styles.MainFooter__H3}>Обратная связь</H3>
            <Text className={styles.MainFooter__Text}>
              Обратитесь за помощью через форму обратной связи
            </Text>
            <Button
              label="Обратная связь"
              onClick={() => setOpenFeedbackModal(true)}
              style={{ marginTop: '20px' }}
            />
            <AlertsList alerts={alerts} />
            {openFeedbackModal && (
              <FeedbackModal
                isOpen={openFeedbackModal}
                closeHandler={() => setOpenFeedbackModal(false)}
                openAlertHandler={(data) =>
                  setAlerts([
                    {
                      text: 'Сообщение успещно отправлено! Мы свяжимся с вами в ближайшее время',
                      type: 'success',
                    },
                  ])
                }
              />
            )}
          </GridItem>

          <GridItem
            breakpoints={{
              xs: {
                col: 3,
              },
              m: {
                col: 1,
              },
            }}
          >
            <H3 className={styles.MainFooter__H3}>Наши контакты</H3>
            <Text className={styles.MainFooter__Text}>
              Мы на связи по будням с 09:00 до 17:00.
            </Text>
            {settings && (
              <ul className={styles.MainFooter__Ul}>
                <li>
                  <span>Почта:</span>{' '}
                  <a
                    href={`mailto:${
                      settings?.find((s) => s.key == 'contactEmail')?.value
                    }`}
                  >
                    {settings?.find((s) => s.key == 'contactEmail')?.value}
                  </a>
                </li>
                <li>
                  <span>Телефон:</span>{' '}
                  <a
                    href={`tel:${
                      settings?.find((s) => s.key == 'contactPhone')?.value
                    }`}
                  >
                    {settings?.find((s) => s.key == 'contactPhone')?.value}
                  </a>
                </li>
                <li>
                  <span>
                    {settings?.find((s) => s.key == 'address')?.value}
                  </span>
                </li>
              </ul>
            )}
          </GridItem>
        </Grid>
      </BlockContainer>
    </Card>
  );
};
