import React from 'react';
import styles from './OneServiceContent.module.scss';
export const OneServiceContent = ({ service }) => {
  return (
    <>
      <div
        className={styles.OneServiceContent}
        dangerouslySetInnerHTML={{ __html: service?.description }}
      ></div>
      <div
        className={styles.OneServiceContent}
        dangerouslySetInnerHTML={{ __html: service?.otherInformation }}
      ></div>
    </>
  );
};
