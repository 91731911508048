import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getCourseStatus, getOneCourseById } from '../../api/CourseApi';
import { getOneCourseMaterialById } from '../../api/MaterialApi';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { MainScreen } from '../../components/Home/MainScreen/MainScreen';
import { MaterialGrid } from '../../components/MaterialGrid/MaterialGrid';
import { useApi } from '../../hooks/useApi';
import { canStartCourse } from '../../utils/canStartCourse';
import { sort } from '../../utils/sort';
import { URLS } from '../../utils/urls.util';
import { getCusomerPermissions } from '../../api/UserApi';
import {
  getOneTestById,
  getTestResult,
  setQuestionResult,
} from '../../api/TestApi';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import { Card } from '@consta/uikit/Card';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';

import { List, ListBox } from '@consta/uikit/ListCanary';
import { Button } from '@consta/uikit/Button';
import { Informer } from '@consta/uikit/Informer';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { IconArrowLeft } from '@consta/uikit/IconArrowLeft';
import styles from './QuestionPage.module.scss';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { BlockContainer } from '../../components/Default/BlockContainer/BlockContainer';
export const QuestionPage = () => {
  const { courseId, materialId, testId, questionId } = useParams();
  const [test, setTest] = useState({});
  const [question, setQuestion] = useState({});
  const [settings, setSettings] = useState({});
  const [testNavigation, setTestNavigation] = useState({});
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [checkResult, setCheckResult] = useState({ canView: true });
  const testApi = useApi(getOneTestById);
  const setQuestionResultApi = useApi(setQuestionResult);
  const getTestResultApi = useApi(getTestResult);

  useEffect(() => {
    testApi
      .sendRequest({
        testId,
        relations: ['questions', 'questions.answers'],
      })
      .then((result) => {
        setTest(result);
        setQuestion(result?.questions?.find((q) => q.id == questionId));
        const currentQuestionIndex = result?.questions?.findIndex(
          (q) => q.id == questionId,
        );
        setTestNavigation({
          currentId: result?.questions[currentQuestionIndex]?.id,
          nextId: result?.questions[currentQuestionIndex + 1]
            ? result?.questions[currentQuestionIndex + 1]?.id
            : null,
          prevId: result?.questions[currentQuestionIndex - 1]
            ? result?.questions[currentQuestionIndex - 1]?.id
            : result?.questions[currentQuestionIndex - 1],
        });
      });
  }, []);

  const setQuestionResultHandler = async () => {
    const testResult = await getTestResultApi.sendRequest({ testId });
    const { nextLink } = await setQuestionResultApi.sendRequest({
      test: {
        id: testId,
      },
      material: {
        id: materialId,
      },
      course: {
        id: courseId,
      },
      question: {
        id: questionId,
      },
      answers: selectedAnswers?.map((a) => ({ id: a })),
      testResult: {
        id: testResult.id,
      },
    });
    window.location = nextLink;
  };

  const breadcrumbs = [
    {
      label: 'Главная',
      href: URLS.HOME,
    },
    {
      label: 'Курсы',
      href: URLS.COURSES_PAGE,
    },
  ];

  return (
    <>
      {!checkResult?.canView && (
        <Navigate to={URLS.COURSES_PAGE + '/' + courseId} replace={true} />
      )}
      <RedirectComponent />
      <MainContainer
        seoTitle={question?.question}
        showFooter={true}
        auth={true}
        breadcrumbs={breadcrumbs}
        settingsHandler={(data) => setSettings(data)}
        permissions={{}}
      >
        <BlockContainer style={{ paddingTop: '48px' }}>
          <Grid cols="12">
            <GridItem
              col="6"
              breakpoints={{
                xs: {
                  col: 12,
                },
                s: {
                  col: 12,
                },
                l: {
                  col: 6,
                },
              }}
            >
              <Text
                as="h1"
                view="h1"
                size="3xl"
                dangerouslySetInnerHTML={{ __html: test?.title }}
              ></Text>
              <Card
                verticalSpace="2xl"
                horizontalSpace="2xl"
                border={true}
                shadow={false}
                style={{ background: '#F5F7FB' }}
              >
                <Text size="l" as="h3">
                  {question?.question}
                </Text>

                {question?.answers?.length && (
                  <>
                    {question?.answers?.filter((a) => a.isRight)?.length > 1 ? (
                      <>
                        {question?.answers?.map((answer) => (
                          <FormControlLabel
                            sx={{ width: '100%' }}
                            control={
                              <Checkbox
                                checked={selectedAnswers?.some(
                                  (i) => i == answer.id,
                                )}
                                onChange={({ target: { checked } }) => {
                                  const newset = new Set(selectedAnswers);
                                  if (checked) {
                                    newset.add(answer.id);
                                  } else {
                                    newset.delete(answer.id);
                                  }
                                  setSelectedAnswers(Array.from(newset));
                                }}
                              />
                            }
                            label={answer.answer}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedAnswers ? selectedAnswers[0] : ''}
                          onChange={(event) =>
                            setSelectedAnswers([event.target.value])
                          }
                        >
                          {question?.answers?.map((answer) => (
                            <FormControlLabel
                              value={answer?.id}
                              control={<Radio />}
                              label={answer?.answer}
                            />
                          ))}
                        </RadioGroup>
                      </>
                    )}
                  </>
                )}
              </Card>
            </GridItem>
            <GridItem col="3"></GridItem>
            <GridItem
              col="3"
              breakpoints={{
                xs: {
                  col: 12,
                },
                s: {
                  col: 12,
                },
                l: {
                  col: 3,
                },
              }}
            >
              <div className={styles.QuestionsList__Sidebar}>
                {test?.questions?.length && (
                  <ListBox
                    className={styles.QuestionsList__ListBox}
                    shadow
                    border
                  >
                    <List
                      placeholder="Выберите вариант"
                      items={test?.questions?.map((q, i) => ({
                        label: q.question,
                        id: i,
                        status: false,
                        onClick: () =>
                          (window.location = `/education/${courseId}/${materialId}/${testId}/${q.id}`),
                      }))}
                    />
                  </ListBox>
                )}

                <div className={styles.QuestionsList__Pagination}>
                  <Button
                    label="Предыдущий вопрос"
                    view="ghost"
                    iconLeft={IconArrowLeft}
                    onlyIcon
                    form="round"
                    disabled={!testNavigation.prevId}
                    onClick={() =>
                      (window.location = `/education/${courseId}/${materialId}/${testId}/${testNavigation.prevId}`)
                    }
                  />
                  <Text as="p" view="p" size="m"></Text>
                  <Button
                    label="Следующий вопрос"
                    view="ghost"
                    iconLeft={IconArrowRight}
                    onlyIcon
                    form="round"
                    disabled={!testNavigation.nextId}
                    onClick={() =>
                      (window.location = `/education/${courseId}/${materialId}/${testId}/${testNavigation?.nextId}`)
                    }
                  />
                </div>

                <Button
                  label="Продолжить"
                  width="full"
                  disabled={selectedAnswers?.length == 0}
                  onClick={() => setQuestionResultHandler()}
                />
              </div>
            </GridItem>
          </Grid>
        </BlockContainer>
      </MainContainer>
    </>
  );
};
