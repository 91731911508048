import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginForm } from '../../components/Default/Form/LoginForm';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { LoginFormContaner } from '../../components/Login/LoginFormContaner';
import { URLS } from '../../utils/urls.util';
import { useCookies } from 'react-cookie';
import { ForgotPasswordForm } from '../../components/Default/Form/ForgotPasswordForm';
import { Typography } from '@mui/material';

export const ForgotPasswordPage = ({ settings }) => {
  const [cookies, setCookie] = useCookies(['jwt']);
  return (
    <>
      {cookies?.jwt && !cookies?.jwt?.message && <Navigate to={URLS.HOME} />}
      <MainContainer seoTitle="Восстановление пароля">
        <LoginFormContaner>
          <Typography as="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
            Восстановление пароля
          </Typography>
          <ForgotPasswordForm />
        </LoginFormContaner>
      </MainContainer>
    </>
  );
};
