import { Card } from '@consta/uikit/Card';
import React from 'react';
import { ProgressLine } from '@consta/uikit/ProgressLine';
import styles from './CertItem.module.scss';
import { Text } from '@consta/uikit/Text';

import { FileCert } from '../File/FileCert';
import { CertificatePDF } from '../CertificatePDF/CertificatePDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

export const CertItemSmall = ({ percent, cert, ...props }) => {
  return (
    <Card
      border={true}
      shadow={false}
      horizontalSpace="m"
      verticalSpace="m"
      className={styles.CertItem}
      {...props}
    >
      <Text className={styles.CourseItem__Title}>{cert?.company?.inn}</Text>
      <PDFDownloadLink
        document={<CertificatePDF cert={cert} />}
        fileName={cert?.company?.inn}
      >
        {({ blob, url, loading, error }) =>
          cert && (
            <FileCert
              loading={loading}
              cert={cert}
              small={true}
              style={{ marginTop: '20px' }}
            />
          )
        }
      </PDFDownloadLink>
    </Card>
  );
};
