import axios from 'axios';
import Cookies from 'js-cookie';
import { ENV } from '../utils/env';

export const createCertificate = async ({
  courseId = 'null',
  testId = 'null',
  materialId = 'null',
  serviceId = 'null',
  expireDate,
}) => {
  try {
    const token = Cookies.get('jwt');
    const formdata = new FormData();
    formdata.append('courseId', courseId);
    formdata.append('token', token);
    formdata.append('testId', testId);
    formdata.append('materialId', materialId);
    formdata.append('serviceId', serviceId);
    formdata.append('expireDate', expireDate);

    let config = {
      method: 'post',
      url: `${ENV.API_CUSTOM_URL}/certificates/create`,
      data: formdata,
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};

export const getCertificate = async ({
  courseId,
  testId,
  materialId,
  serviceId,
  expireDate,
}) => {
  try {
    const token = Cookies.get('jwt');
    const formdata = new FormData();

    formdata.append('token', token);
    if (testId) {
      formdata.append('testId', testId);
    }
    if (materialId) {
      formdata.append('materialId', materialId);
    }
    if (courseId) {
      formdata.append('courseId', courseId);
    }
    if (serviceId) {
      formdata.append('serviceId', serviceId);
    }
    if (expireDate) {
      formdata.append('expireDate', expireDate);
    }

    let config = {
      method: 'post',
      url: `${ENV.API_CUSTOM_URL}/certificates/certificate-data`,
      data: formdata,
    };

    const resultData = await axios(config);
    return resultData.data;
  } catch (error) {
    throw error;
  }
};
