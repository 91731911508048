// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FinalTest_FinalTest__ui2WQ {\n  background: rgba(0, 32, 51, 0.0784313725);\n}\n.FinalTest_FinalTest__CallBack__GAYnK {\n  width: 300px !important;\n}\n\n@media only screen and (max-width: 768px) {\n  .FinalTest_FinalTest__ui2WQ {\n    padding: 20px !important;\n    justify-content: start !important;\n  }\n  .FinalTest_FinalTest__ui2WQ a {\n    margin-top: 30px;\n    margin-right: auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/FinalTest/FinalTest.module.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;AACJ;AAAI;EACI,uBAAA;AAER;;AAEA;EACI;IACI,wBAAA;IACA,iCAAA;EACN;EAAK;IACC,gBAAA;IACA,kBAAA;EAEN;AACF","sourcesContent":[".FinalTest {\n    background: #00203314;\n    &__CallBack {\n        width: 300px !important;\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .FinalTest {\n        padding: 20px !important;\n        justify-content: start !important;\n       a {\n        margin-top: 30px;\n        margin-right: auto;\n       }\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FinalTest": "FinalTest_FinalTest__ui2WQ",
	"FinalTest__CallBack": "FinalTest_FinalTest__CallBack__GAYnK"
};
export default ___CSS_LOADER_EXPORT___;
