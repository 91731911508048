// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TestResult_TestResult__H5\\+B1 {\n  position: relative;\n  overflow: hidden;\n}\n.TestResult_TestResult__Background__e3pT0 {\n  position: absolute;\n  z-index: -2;\n  width: 700px;\n  height: 1000px;\n  background: linear-gradient(90deg, #DEE4E8 0%, #0174DE 100%);\n  display: block;\n  transform: rotate(45deg);\n  top: 0px;\n  right: -150px;\n}\n.TestResult_TestResult__ActionButton__DePHm {\n  min-width: 350px !important;\n  margin-top: 50px;\n  display: inline-block;\n  text-align: center;\n}\n.TestResult_TestResult__Cert__sN3P\\+ {\n  width: 315px;\n  height: 380px;\n  margin-left: auto;\n  background: #ccc;\n  border-radius: 5px;\n  overflow: hidden;\n}\n.TestResult_TestResult__Cert__sN3P\\+ iframe {\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/TestResult/TestResult.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,4DAAA;EACA,cAAA;EACA,wBAAA;EACA,QAAA;EACA,aAAA;AAER;AAAI;EACI,2BAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;AAER;AAAI;EACI,YAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAER;AADQ;EACI,YAAA;AAGZ","sourcesContent":[".TestResult {\n    position: relative;\n    overflow: hidden;\n    &__Background {\n        position: absolute;\n        z-index: -2;\n        width: 700px;\n        height: 1000px;\n        background: linear-gradient(90deg, #DEE4E8 0%, #0174DE 100%);\n        display: block;\n        transform: rotate(45deg);\n        top: -00px;\n        right: -150px;\n    }\n    &__ActionButton {\n        min-width: 350px !important;\n        margin-top: 50px;\n        display: inline-block;\n        text-align: center;\n    }\n    &__Cert {\n        width: 315px;\n        height: 380px;\n        margin-left: auto;\n        background: #ccc;\n        border-radius: 5px;\n        overflow: hidden;\n        iframe {\n            height: 100%;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestResult": "TestResult_TestResult__H5+B1",
	"TestResult__Background": "TestResult_TestResult__Background__e3pT0",
	"TestResult__ActionButton": "TestResult_TestResult__ActionButton__DePHm",
	"TestResult__Cert": "TestResult_TestResult__Cert__sN3P+"
};
export default ___CSS_LOADER_EXPORT___;
