import React, { useState } from 'react';
import styles from './LoginFormContaner.module.scss';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Link, useParams } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { Button } from '@mui/material';
export const LoginFormContaner = ({ children, success, page }) => {
  return (
    <Grid cols={1} yAlign="center">
      <GridItem>
        <div className={styles.LoginFormContaner}>
          {!success && page && (
            <div className={styles.LoginFormContaner__ButtonGroup}>
              <Button
                variant={page == 'registration' ? 'contained' : 'outlined'}
                className={styles.LoginFormContaner__ButtonGroup__button_first}
                href="/registration"
              >
                Регистрация
              </Button>
              <Button
                variant={page == 'login' ? 'contained' : 'outlined'}
                className={styles.LoginFormContaner__ButtonGroup__button_second}
                href="/login"
              >
                Войти
              </Button>
            </div>
          )}

          {children}
        </div>
      </GridItem>
    </Grid>
  );
};
