import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { IconAllDone } from '@consta/uikit/IconAllDone';
import { IconDownload } from '@consta/uikit/IconDownload';
import { Text } from '@consta/uikit/Text';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { getCourseStatus } from '../../api/CourseApi';
import { useApi } from '../../hooks/useApi';
import { useSiteChecker } from '../../hooks/useSiteChecker';
import { CertificatePDF } from '../CertificatePDF/CertificatePDF';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { ServiceItem } from '../ServiceItem/ServiceItem';
import styles from './HomeCoursesGrid.module.scss';
import { checkAllow } from '../../api/ServicesApi';
import { getCertificate } from '../../api/UserApi';
export const HomeCoursesGrid = ({ services }) => {
  const [checkResult, setCheckResult] = useState({});
  const [settings, setSettings] = useState({});

  const { getData, data } = useSiteChecker();
  const checkAllowApi = useApi(checkAllow);
  const [certificate, setCertificate] = useState({});
  const getCertificateApi = useApi(getCertificate);

  useEffect(() => {
    const sendRequests = async () => {
      const result = await checkAllowApi.sendRequest({});
      const cert = await getCertificateApi.sendRequest();
      setCertificate(cert);
      setCheckResult(result);
    };

    sendRequests();
  }, []);
  return (
    <div className={styles.HomeCoursesGrid} style={{ marginTop: '45px' }}>
      <BlockContainer>
        <Grid cols={12} gap="3xl">
          <GridItem
            col={3}
            breakpoints={{
              xs: {
                col: 12,
              },
              s: {
                col: 12,
              },
              l: {
                col: 3,
              },
            }}
          >
            <Card
              verticalSpace="l"
              horizontalSpace="l"
              border={false}
              shadow={false}
              className={styles.HomeCoursesProgress}
            >
              <Text
                as="h3"
                size="xl"
                style={{ margin: '30px 0', marginTop: 0 }}
              >
                Услги центра поддержки экспорта
              </Text>
              <Text as="p" size="l" style={{ margin: '30px 0', marginTop: 0 }}>
                Вы сможете заказать услугу после прохождения теста на экспортную
                зрелость, финального итогового тестирования и одного из курсов
                по интересующему вас направлению
              </Text>
              <div className={styles.HomeCoursesProgress__Checkboxs}>
                <div className={styles.HomeCoursesProgress__Checkboxs__Item}>
                  <IconAllDone
                    style={{ marginRight: '10px' }}
                    view={checkResult?.maturityTest ? 'success' : 'alert'}
                  />
                  <Text
                    className={
                      checkResult?.maturityTest
                        ? ''
                        : styles.HomeCoursesProgress__Checkboxs__Item__TextOpacity
                    }
                  >
                    Тест на оценку экспортной зрелости
                  </Text>
                </div>

                <div className={styles.HomeCoursesProgress__Checkboxs__Item}>
                  <IconAllDone
                    style={{ marginRight: '10px' }}
                    view={checkResult?.finalTest ? 'success' : 'alert'}
                  />
                  <Text
                    className={
                      checkResult?.finalTest
                        ? ''
                        : styles.HomeCoursesProgress__Checkboxs__Item__TextOpacity
                    }
                  >
                    Финальное итоговое тестирование
                  </Text>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}
              >
                <Button
                  label="Услуги"
                  // disabled={Object.values(checkResult).some((r) => r === false)}
                  as="a"
                  target="blank"
                  href="/services"
                  size="s"
                />
                <PDFDownloadLink
                  document={<CertificatePDF cert={certificate} />}
                  fileName={certificate?.company?.inn}
                >
                  <Button
                    label="Мой сертификат"
                    view="secondary"
                    size="s"
                    iconLeft={IconDownload}
                    style={{ marginLeft: '10px' }}
                  />
                </PDFDownloadLink>
              </div>
            </Card>
          </GridItem>
          <GridItem col="9">
            <Grid col={12} gap="3xl">
              {services?.map((service) => (
                <GridItem
                  key={service.id}
                  col="4"
                  breakpoints={{
                    xs: {
                      col: 12,
                    },
                    s: {
                      col: 6,
                    },
                    l: {
                      col: 4,
                    },
                  }}
                >
                  <ServiceItem service={service} />
                </GridItem>
              ))}
            </Grid>
          </GridItem>
        </Grid>
      </BlockContainer>
    </div>
  );
};
