import './App.css';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { URLS } from './utils/urls.util';
import { RegistrationPage } from './pages/LoginPage/RegistrationPage';
import { HomePage } from './pages/HomePage/HomePage';
import { CoursesPage } from './pages/CorsesPage/CoursesPage';
import { OneCoursePage } from './pages/OneCoursePage/OneCoursePage';
import { MaterialPage } from './pages/MaterialPage/MaterialPage';
import { ServicesPage } from './pages/ServicesPage/ServicesPage';
import { OneServicePage } from './pages/OneServicePage/OneServicePage';
import { TestPage } from './pages/TestPage/TestPage';
import { SingleTestPage } from './pages/SingleTestPage/SingleTestPage';
import { ProfilePage } from './pages/ProfilePage/ProfilePage';
import { DocsPage } from './pages/DocsPage/DocsPage';
import { ForgotPasswordPage } from './pages/LoginPage/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/LoginPage/ResetPasswordPage';
import { QuestionPage } from './pages/QuestionPage/QuestionPage';
import { TestResult } from './pages/TestResult/TestResult';

function App() {
  return (
    <Theme preset={presetGpnDefault}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Акселератор - Санкт-Петербургский центр экспорта</title>
      </Helmet>
      <Router>
        <Routes>
          <Route path="*" element={<HomePage />} />

          <Route exact path={URLS.LOGIN} element={<LoginPage />} />
          <Route
            exact
            path={URLS.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route
            exact
            path={URLS.RESET_PASSWORD}
            element={<ResetPasswordPage />}
          />
          <Route
            exact
            path={URLS.REGISTRATION}
            element={<RegistrationPage />}
          />

          <Route exact path={URLS.HOME} element={<HomePage />} />
          <Route exact path={URLS.COURSES_PAGE} element={<CoursesPage />} />
          <Route
            exact
            path={URLS.ONE_COURSE_PAGE}
            element={<OneCoursePage />}
          />
          <Route
            exact
            path={URLS.ONE_COURSE_ONE_MATERIAL_PAGE}
            element={<MaterialPage />}
          />
          <Route
            exact
            path={URLS.ONE_COURSE_ONE_MATERIAL_TEST_PAGE}
            element={<TestPage />}
          />
          <Route
            exact
            path={URLS.ONE_COURSE_ONE_MATERIAL_TEST_QUESTION_PAGE}
            element={<QuestionPage />}
          />
          <Route exact path={URLS.SERVICES} element={<ServicesPage />} />
          <Route exact path={URLS.TEST_RESULT} element={<TestResult />} />
          <Route
            exact
            path={URLS.ONE_SERVICE_PAGE}
            element={<OneServicePage />}
          />
          <Route exact path={URLS.SINGLE_TEST} element={<SingleTestPage />} />
          <Route
            exact
            path={URLS.SINGLE_TEST_QEUSTION}
            element={<QuestionPage />}
          />
          <Route
            exact
            path={URLS.SINGLE_TEST_RESULT}
            element={<TestResult />}
          />
          <Route exact path={URLS.PROFILE} element={<ProfilePage />} />
          <Route exact path={URLS.DOCS} element={<DocsPage />} />
        </Routes>
      </Router>
    </Theme>
  );
}

export default App;
