import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import React from 'react';
import aboutImg from '../../assets/images/about.png';
import styles from './AboutCard.module.scss';
export const AboutCard = ({ settings }) => {
  return (
    <Card verticalSpace="l" horizontalSpace="l" className={styles.AboutCard}>
      <Text as="h4" size="xl" style={{ margin: '10px 0' }}>
        О сервисах:
      </Text>
      <Text as="p">
        {settings?.find((s) => s.key == 'aboutServices')?.value}
      </Text>
      <img src={aboutImg} style={{ display: 'block', margin: '0 auto' }} />
    </Card>
  );
};
