import React, { useEffect, useState } from 'react';
import { Layout } from '@consta/uikit/Layout';
import { MainHeader } from '../MainHeader/MainHeader';
import { MainFooter } from '../MainFooter/MainFooter';
import { useSelector } from 'react-redux';
import { AlertsList } from '../AlertsList/AlertsList';
import { useApi } from '../../../hooks/useApi';
import { getAllSettings } from '../../../api/SettingsApi';
import { getMe } from '../../../api/UserApi';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../../../utils/urls.util';

export const MainContainer = ({
  children,
  auth,
  breadcrumbs,
  showFooter,
  hideMenu,
  seoTitle,

  settingsHandler,
  ...props
}) => {
  const alerts = useSelector((state) => state.alerts);
  const [settings, setSettings] = useState([]);
  const settingsApi = useApi(getAllSettings);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    settingsApi.sendRequest().then((result) => {
      setSettings(result);
    });

    if (Object.values(params)?.includes('undefind')) {
      navigate(URLS.NOT_FOUND);
    }
  }, []);
  return (
    <div {...props}>
      <AlertsList alerts={alerts} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Образовательный трек - {seoTitle || ''}</title>

        <link
          rel="icon"
          href="https://static.tildacdn.com/tild6466-3666-4132-b032-343939313264/favicon.ico"
          type="image/x-icon"
        />
        <meta
          name="description"
          content="Комплексная поддержка экспортной деятельности малого и среднего бизнеса Петербурга. Широкий спектр услуг от консультаций экспертов до участия в зарубежных выставках. Бесплатно или на условиях софинансирования."
        />
        <meta property="og:url" content="https://track.spbexport.ru" />
        <meta property="og:title" content="Образовательный трек" />
        <meta
          property="og:description"
          content="Комплексная поддержка экспортной деятельности малого и среднего бизнеса Петербурга. Широкий спектр услуг от консультаций экспертов до участия в зарубежных выставках. Бесплатно или на условиях софинансирования."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://static.tildacdn.com/tild3731-6130-4733-b835-646537373732/-/resize/504x/6.jpeg"
        />
      </Helmet>
      <MainHeader
        breadcrumbs={breadcrumbs}
        auth={auth}
        hideMenu={hideMenu}
        settings={settings}
      />
      {children}
      {showFooter && <MainFooter settings={settings} />}
    </div>
  );
};
