import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMaterialResult } from '../../api/MaterialApi';
import { getOneTestById } from '../../api/TestApi';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { MaterialAboutTesting } from '../MaterialAboutTesting/MaterialAboutTesting';
import { MaterialDescription } from '../MaterialDescription/MaterialDescription';
import { MaterialNavigationList } from '../MaterialNavigationList/MaterialNavigationList';
import { YoutubeVideoPlayer } from '../VideoPlayer/YoutubeVideoPlayer';
import styles from './MaterialGrid.module.scss';
import { RutubeVideoPlayer } from '../VideoPlayer/RuTubeVideoPlayer';

export const MaterialGrid = ({ course, material, settings }) => {
  return (
    <BlockContainer style={{ paddingTop: '48px' }}>
      <Grid cols="12" gap="3xl">
        <GridItem
          col="8"
          breakpoints={{
            xs: {
              col: 9,
            },
            s: {
              col: 7,
            },
            l: {
              col: 8,
            },
          }}
        >
          {settings?.find((s) => s.key === 'videoPlayer')?.value ===
          'youtube' ? (
            <YoutubeVideoPlayer url={material?.youtubeVideo} />
          ) : (
            <RutubeVideoPlayer url={material?.rutubeVideo} />
          )}

          <MaterialDescription material={material} />
        </GridItem>
        <GridItem
          col="4"
          breakpoints={{
            xs: {
              col: 9,
            },
            s: {
              col: 5,
            },
            l: {
              col: 4,
            },
          }}
        >
          <MaterialNavigationList course={course} />
          <MaterialAboutTesting text={course?.aboutTesting} />
        </GridItem>
      </Grid>
      <Grid cols="12" gap="3xl">
        <GridItem col="12">
          <Card
            verticalSpace="3xl"
            horizontalSpace="3xl"
            border={false}
            shadow={false}
            className={styles.MaterialGrid__TestCard}
          >
            <Button
              label="Продолжить"
              onClick={() =>
                (window.location = `/education/${course?.id}/${material.id}/${material?.test?.id}`)
              }
            />
          </Card>
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};
