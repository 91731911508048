// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaterialAboutTesting_MaterialAboutTesting__55Vqz {\n  background: #F5F7FB;\n}\n.MaterialAboutTesting_MaterialAboutTesting__55Vqz p {\n  color: rgba(0, 32, 51, 0.6);\n  font-size: 14px;\n}\n.MaterialAboutTesting_MaterialAboutTesting__55Vqz h4 {\n  line-height: 1;\n  padding: 0 !important;\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/MaterialAboutTesting/MaterialAboutTesting.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,2BAAA;EACA,eAAA;AACR;AAEI;EACI,cAAA;EACA,qBAAA;EACA,SAAA;AAAR","sourcesContent":[".MaterialAboutTesting {\n    background: #F5F7FB;\n    \n    p{\n        color : #00203399;\n        font-size: 14px;\n\n    }\n    h4 {\n        line-height: 1;\n        padding: 0 !important;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MaterialAboutTesting": "MaterialAboutTesting_MaterialAboutTesting__55Vqz"
};
export default ___CSS_LOADER_EXPORT___;
