import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getOneTestById } from '../../api/TestApi';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { TestPreview } from '../../components/TestPreview/TestPreview';

export const TestPage = () => {
  const [test, setTest] = useState({});
  const testApi = useApi(getOneTestById);

  const { courseId, materialId, testId } = useParams();
  const [checkResult, setCheckResult] = useState({ canView: true });

  useEffect(() => {
    testApi
      .sendRequest({
        testId,
        relations: ['questions', 'questions.answers'],
      })
      .then((result) => {
        setTest(result);
      });
  }, []);
  return (
    <>
      {!checkResult.canView && (
        <Navigate to={URLS.COURSES_PAGE + '/' + courseId} replace={true} />
      )}
      <RedirectComponent />

      <TestPreview
        title={test?.title}
        description={test?.description}
        actionText="Начать тест"
        actionUrl={`/education/${courseId}/${materialId}/${test?.id}/${
          test?.questions && test?.questions[0]?.id
        }`}
      />
    </>
  );
};
