import React, { useEffect, useState } from 'react';
import { Card } from '@consta/uikit/Card';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import styles from './CoursesPageSidebar.module.scss';
import { Text } from '@consta/uikit/Text';
import { Link } from 'react-router-dom';
import { IconFavorite } from '@consta/uikit/IconFavorite';
import { CourseItemInSidebar } from '../CourseItem/CourseItemInSidebar';
import { useApi } from '../../hooks/useApi';
import { getViewedCoursesIds } from '../../api/MaterialApi';
import { getAllCourses, getStartedCourses } from '../../api/CourseApi';
import { AboutCard } from '../AboutCard/AboutCard';

export const CoursesPageSidebar = () => {
  const [courses, setCourses] = useState([]);
  const getStartedCoursesApi = useApi(getStartedCourses);
  useEffect(() => {
    getStartedCoursesApi
      .sendRequest({
        limit: 4,
        offset: 0,
      })
      .then(([result, count]) => setCourses(result));
  }, []);
  return (
    <>
      {!!courses?.length ? (
        <Card
          shadow={false}
          border={true}
          verticalSpace="l"
          horizontalSpace="l"
        >
          <Text as="h4" size="l">
            Продолжить обучение
          </Text>
          {courses.map((item) => (
            <CourseItemInSidebar key={item?.id} item={item} />
          ))}
        </Card>
      ) : (
        <AboutCard />
      )}
    </>
  );
};
