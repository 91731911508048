import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useParams } from 'react-router-dom';
import { getOneServiceById } from '../../api/ServicesApi';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { MainScreen } from '../../components/Home/MainScreen/MainScreen';
import { OneCourseGrid } from '../../components/OneCourseGrid/OneCourseGrid';
import { OneServiceGrid } from '../../components/OneServiceGrid/OneServiceGrid';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { getCusomerPermissions } from '../../api/UserApi';

export const OneServicePage = () => {
  const [service, setService] = useState({});
  const { serviceId } = useParams();
  const [cookies, setCookie] = useCookies(['jwt']);
  const [permissions, setPermissions] = useState([]);
  const oneServiceApi = useApi(getOneServiceById);
  const permissionsApi = useApi(getCusomerPermissions);
  useEffect(() => {
    oneServiceApi
      .sendRequest({ serviceId, relations: ['file', 'course'] })
      .then((result) => {
        setService(result);
      });
    permissionsApi.sendRequest().then((result) => setPermissions(result));
  }, []);
  const breadcrumbs = [
    {
      label: 'Главная',
      href: URLS.HOME,
    },
    {
      label: 'Услуги',
      href: URLS.SERVICES,
    },
    {
      label: service?.title,
      href: URLS.SERVICES + '/' + service?.id,
    },
  ];
  return (
    <>
      <RedirectComponent />
      <MainContainer
        seoTitle={service?.title}
        showFooter={true}
        auth={true}
        breadcrumbs={breadcrumbs}
        permissions={permissions}
      >
        <OneServiceGrid service={service} />
      </MainContainer>
    </>
  );
};
