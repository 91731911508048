import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { ProgressLine } from '@consta/uikit/ProgressLine';
import { Text } from '@consta/uikit/Text';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCourseStatus } from '../../api/CourseApi';
import {
  getMaterialsResultCount,
  getMaterialsStatus,
} from '../../api/MaterialApi';
import { getOneMediaByMediaId } from '../../api/MediaApi';
import { getOneServiceById } from '../../api/ServicesApi';
import notFoundImg from '../../assets/images/no-image.png';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { Devider } from '../Default/Devider/Devider';
import { PopoverBlock } from '../Default/PopoverBlock/PopoverBlock';
import styles from './CourseItem.module.scss';

export const CourseItemInCoursesPage = ({ item }) => {
  const [title, setTitle] = useState('');
  const [serviceTitle, setServiceTitle] = useState('');

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const anchorRefTitle = useRef(null);

  const handleHoverOnAnchor = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  useEffect(() => {
    if (item?.title?.length > 27) {
      setTitle(item?.title?.slice(0, 27) + '...');
    } else {
      setTitle(item?.title);
    }
  }, [item]);

  useEffect(() => {
    if (item?.service?.title?.length > 15) {
      setServiceTitle(item?.service?.title?.slice(0, 15) + '...');
    } else {
      setServiceTitle(item?.service?.title);
    }
  }, [item?.service]);

  return (
    <Card
      shadow={false}
      border={true}
      className={styles.CourseItemInCoursesPage}
    >
      <Link
        to={URLS.COURSES_PAGE + '/' + item.id}
        className={styles.CourseItemInCoursesPage__Title}
      >
        <div className={styles.CourseItemInCoursesPage__Image}>
          <div
            ref={anchorRefTitle}
            onMouseOver={handleHoverOnAnchor}
            onMouseLeave={handleHoverOnAnchor}
          >
            {item?.status === 'complete' && (
              <div className={styles.CourseItemInCoursesPage__Status}>
                Пройден
              </div>
            )}
            <img src={item?.imageUrl || notFoundImg} />
            {isPopoverVisible && (
              <PopoverBlock
                isPopoverVisible={isPopoverVisible}
                anchorRef={anchorRefTitle}
                text={item?.title}
              />
            )}
          </div>
        </div>
      </Link>
      <Card verticalSpace="l" horizontalSpace="l">
        <Link
          to={URLS.COURSES_PAGE + '/' + item.id}
          className={styles.CourseItemInCoursesPage__Title}
        >
          <Text as="h5">{title}</Text>
        </Link>

        <Text as="p" className={styles.CourseItemInCoursesPage__PercentTitle}>
          Пройден на {item.progress || 0}%
        </Text>

        <ProgressLine value={item.progress} style={{ marginTop: '8px' }} />
        <Devider />
        <Grid
          cols="12"
          yAlign="center"
          className={styles.CourseItemInCoursesPage__Footer}
        >
          <GridItem col={6}>
            <Text as="p">
              <b>Заданий - {item?.materials?.length || 0}</b>
            </Text>
          </GridItem>
          <GridItem col="6" style={{ textAlign: 'center' }}>
            <Text as="p">Открывает доступ к</Text>
            <Link to={URLS.SERVICES + '/' + item?.service?.id}>
              "{serviceTitle}"
            </Link>
          </GridItem>
        </Grid>
      </Card>
    </Card>
  );
};
