import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { Tooltip } from '@consta/uikit/Tooltip';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Form.module.scss';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { Text } from '@consta/uikit/Text';
import { useApi } from '../../../hooks/useApi';
import { createUser } from '../../../api/UserApi';
import { IconAllDone } from '@consta/uikit/IconAllDone';
import { URLS } from '../../../utils/urls.util';
import { sendNotification } from '../../../api/NotificationApi';

export const RegistrationForm = ({ successHandler }) => {
  const [position, setPosition] = useState(undefined);
  const [user, setUser] = useState({});
  const [success, setSuccess] = useState(false);
  const userApi = useApi(createUser);

  const onButtonClick = (event) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };
  const registration = async () => {
    const result = await userApi.sendRequest(user);
    if (result.id) {
      setSuccess(result);
      successHandler(result);
    }
  };
  return (
    <>
      {!success && (
        <div style={{ marginTop: '40px' }}>
          <p style={{ color: '#00203399' }}>
            Введите ваше имя и фамилию
            <span style={{ color: '#eb5757' }}>*</span>
          </p>
          <div style={{ display: 'flex' }}>
            <TextField
              required
              value={user?.firstName}
              placeholder="Имя"
              width="full"
              withClearButton
              style={{ marginRight: '7px' }}
              onChange={({ value }) => setUser({ ...user, firstName: value })}
            />
            <TextField
              required
              value={user?.lastName}
              placeholder="Фамилия"
              width="full"
              withClearButton
              style={{ marginLeft: '7px' }}
              onChange={({ value }) => setUser({ ...user, lastName: value })}
            />
          </div>

          <TextField
            label="Введите ваш e-mail"
            required
            value={user?.email}
            placeholder="myadress@gmail.com"
            width="full"
            withClearButton
            style={{ marginTop: '16px' }}
            onChange={({ value }) => setUser({ ...user, email: value })}
          />
          <Tooltip
            size="s"
            direction="leftUp"
            position={position}
            onClickOutside={() => setPosition(undefined)}
          >
            Узнать свой ИНН предпринимателя или юридического лица можно в личном
            кабинете на сайте Федеральной налоговой службы, а также в Едином
            федеральном реестре юрлиц.
          </Tooltip>
          <Text className={styles.CustomLabel}>
            <IconQuestion size="m" onClick={onButtonClick} />
            Введите ИНН вашей компании
          </Text>
          <TextField
            required
            placeholder="0000000000"
            width="full"
            withClearButton
            value={user?.companyInn}
            onChange={({ value }) => setUser({ ...user, companyInn: value })}
          />
          <TextField
            label="Придумайте пароль"
            required
            type="password"
            placeholder="Придумайте пароль"
            width="full"
            style={{ marginTop: '16px' }}
            value={user?.password}
            withClearButton
            onChange={({ value }) => setUser({ ...user, password: value })}
          />

          <Button
            label="Продолжить"
            width="full"
            view="primary"
            style={{ marginTop: '32px' }}
            onClick={registration}
          />
          {/* <Button
            label="Войти через Гос. услуги"
            width="full"
            view="secondary"
            style={{ marginTop: '12px' }}
          /> */}
        </div>
      )}
      {success && (
        <div className={styles.Success}>
          <IconAllDone size="l" view="success" />
          <Text as="h1" size="xl">
            Успешная регистрация
          </Text>
          <Text as="p">
            Мы отправили письмо с подтверждением на{' '}
            <b style={{ textDecoration: 'underline' }}>{success.email}</b>.
            Пожалуйста, пройдите по ссылке в письме, чтобы продолжить работу с
            системой.
          </Text>
          <Button label="Войти в аккаунт" as="a" href={URLS.LOGIN} />
        </div>
      )}
    </>
  );
};
