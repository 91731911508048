import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import React, { useEffect, useState } from 'react';

import { URLS } from '../../utils/urls.util';
import { AboutCard } from '../AboutCard/AboutCard';
import { CourseItemOnHome } from '../CourseItem/CourseItemOnHome';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import styles from './CoursesBlock.module.scss';

export const CoursesBlock = ({ courses, settings }) => {
  const [aboutSize, setAboutSize] = useState(6);
  useEffect(() => {
    if (courses?.length == 1) {
      setAboutSize(9);
    } else if (courses?.length == 2) {
      setAboutSize(6);
    } else if (courses?.length == 3) {
      setAboutSize(3);
    }
  }, [courses]);
  return (
    <BlockContainer className={styles.CoursesBlock}>
      <Grid cols={12} gap="xl">
        {courses?.length < 4 && (
          <GridItem
            col={aboutSize}
            breakpoints={{
              xs: {
                col: 12,
              },
              s: {
                col: 12,
              },
              l: {
                col: aboutSize,
              },
            }}
          >
            <AboutCard settings={settings} />
          </GridItem>
        )}
        {courses?.map((course) => (
          <GridItem
            key={course.id}
            col={3}
            breakpoints={{
              xs: {
                col: 12,
              },

              s: {
                col: 6,
              },
              l: {
                col: 3,
              },
            }}
          >
            <CourseItemOnHome course={course} />
          </GridItem>
        ))}
      </Grid>
      <Grid cols="1" xAlign="center">
        <GridItem col="1">
          <Button
            label="Смотреть все курсы"
            view="secondary"
            style={{ marginTop: '30px' }}
            as="a"
            href={URLS?.COURSES_PAGE}
          />
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};
