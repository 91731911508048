import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useParams } from 'react-router-dom';
import { getCourseStatus, getOneCourseById } from '../../api/CourseApi';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { MainScreen } from '../../components/Home/MainScreen/MainScreen';
import { OneCourseGrid } from '../../components/OneCourseGrid/OneCourseGrid';
import { useApi } from '../../hooks/useApi';
import { sort } from '../../utils/sort';
import { URLS } from '../../utils/urls.util';
import { getCusomerPermissions } from '../../api/UserApi';

export const OneCoursePage = () => {
  const [course, setCourse] = useState({});
  const courseApi = useApi(getOneCourseById);
  const { courseId } = useParams();

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  useEffect(() => {
    courseApi
      .sendRequest({
        courseId,
        relations: ['materials', 'finalTest', 'service', 'service.file'],
      })
      .then((result) => {
        result.materials = sort(result.materials);
        setCourse(result);
        setBreadcrumbs([
          {
            label: 'Главная',
            href: URLS.HOME,
          },
          {
            label: 'Курсы',
            href: URLS.COURSES_PAGE,
          },
          {
            label: result?.title?.rendered,
            href: URLS.COURSES_PAGE + '/' + result?.id,
          },
        ]);
      });
  }, [courseId]);

  return (
    <>
      <RedirectComponent />
      <MainContainer
        seoTitle={course?.title}
        showFooter={true}
        auth={true}
        breadcrumbs={breadcrumbs}
      >
        <MainScreen
          title={`Курс: ${course?.title}`}
          titleSize="4xl"
          style={{ height: '40vh', paddingTop: '50px' }}
        />

        <OneCourseGrid course={course} />
      </MainContainer>
    </>
  );
};
