import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginForm } from '../../components/Default/Form/LoginForm';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { LoginFormContaner } from '../../components/Login/LoginFormContaner';
import { URLS } from '../../utils/urls.util';
import { useCookies } from 'react-cookie';

export const LoginPage = ({ settings }) => {
  const [cookies, setCookie] = useCookies(['jwt']);
  return (
    <>
      {cookies?.jwt && !cookies?.jwt?.message && <Navigate to={URLS.HOME} />}
      <MainContainer seoTitle="Вход">
        <LoginFormContaner page="login">
          <LoginForm />
        </LoginFormContaner>
      </MainContainer>
    </>
  );
};
