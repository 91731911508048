// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainFooter_MainFooter__4Vf9r {\n  background-color: #002033;\n  color: #fff;\n  padding-bottom: 0;\n}\n.MainFooter_MainFooter__H3__vcikT {\n  color: #fff;\n}\n.MainFooter_MainFooter__Text__TQRQu {\n  color: #fff;\n}\n.MainFooter_MainFooter__Ul__zXvOZ {\n  list-style: none;\n  padding: 0;\n}\n.MainFooter_MainFooter__Ul__zXvOZ li {\n  display: flex;\n  padding-bottom: 10px;\n}\n.MainFooter_MainFooter__Ul__zXvOZ li a {\n  margin-left: 10px;\n  color: rgb(156, 147, 255);\n}\n\n@media only screen and (max-width: 768px) {\n  .MainFooter_MainFooter__H3__vcikT {\n    margin-top: 30px !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Default/MainFooter/MainFooter.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,iBAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EACI,WAAA;AAER;AAAI;EACI,gBAAA;EACA,UAAA;AAER;AADQ;EACI,aAAA;EAEA,oBAAA;AAEZ;AADY;EACI,iBAAA;EACA,yBAAA;AAGhB;;AAGA;EAEQ;IACI,2BAAA;EADV;AACF","sourcesContent":[".MainFooter {\n    background-color: #002033;\n    color: #fff;\n    padding-bottom: 0;\n    &__H3 {\n        color: #fff;\n    }\n    &__Text {\n        color: #fff;\n    }\n    &__Ul {\n        list-style: none;\n        padding: 0;\n        li{\n            display: flex;\n\n            padding-bottom: 10px;\n            a {\n                margin-left: 10px;\n                color: rgba(156, 147, 255, 1)\n            }\n        }\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .MainFooter {\n        &__H3 {\n            margin-top: 30px !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainFooter": "MainFooter_MainFooter__4Vf9r",
	"MainFooter__H3": "MainFooter_MainFooter__H3__vcikT",
	"MainFooter__Text": "MainFooter_MainFooter__Text__TQRQu",
	"MainFooter__Ul": "MainFooter_MainFooter__Ul__zXvOZ"
};
export default ___CSS_LOADER_EXPORT___;
