// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeCoursesGrid_HomeCoursesProgress__c7R\\+x {\n  background: #eee;\n}\n.HomeCoursesGrid_HomeCoursesProgress__Checkboxs__6FGm0 {\n  display: block;\n}\n.HomeCoursesGrid_HomeCoursesProgress__Checkboxs__Item__izML\\+ {\n  display: flex;\n  font-size: 14px;\n}\n.HomeCoursesGrid_HomeCoursesProgress__Checkboxs__Item__TextOpacity__bFjJV {\n  opacity: 0.5;\n}\n\n@media only screen and (max-width: 480px) {\n  .HomeCoursesGrid_HomeCoursesProgress__c7R\\+x {\n    width: 76%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/HomeCoursesGrid/HomeCoursesGrid.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACG,cAAA;AAEP;AADO;EACG,aAAA;EACA,eAAA;AAGV;AAFU;EACG,YAAA;AAIb;;AAEC;EACE;IACG,UAAA;EACJ;AACF","sourcesContent":[".HomeCoursesProgress {\n    background: #eee;\n    &__Checkboxs {\n       display: block;\n       &__Item {\n          display: flex;\n          font-size: 14px;\n          &__TextOpacity {\n             opacity: 0.5;\n          }\n       }\n    }\n }\n\n @media only screen and (max-width: 480px) {\n   .HomeCoursesProgress {\n      width: 76%;\n   }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeCoursesProgress": "HomeCoursesGrid_HomeCoursesProgress__c7R+x",
	"HomeCoursesProgress__Checkboxs": "HomeCoursesGrid_HomeCoursesProgress__Checkboxs__6FGm0",
	"HomeCoursesProgress__Checkboxs__Item": "HomeCoursesGrid_HomeCoursesProgress__Checkboxs__Item__izML+",
	"HomeCoursesProgress__Checkboxs__Item__TextOpacity": "HomeCoursesGrid_HomeCoursesProgress__Checkboxs__Item__TextOpacity__bFjJV"
};
export default ___CSS_LOADER_EXPORT___;
