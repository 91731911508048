// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaterialItem_MaterialItemSmall__wh3bm {\n  border-bottom: solid 1px #F5F7FB;\n  cursor: pointer;\n}\n.MaterialItem_MaterialItemSmall__wh3bm:hover {\n  background: #F5F7FB;\n}\n.MaterialItem_MaterialItemSmall__Image__Uh7CN {\n  width: 100%;\n  height: 75px;\n  overflow: hidden;\n}\n.MaterialItem_MaterialItemSmall__Image__Uh7CN img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.MaterialItem_MaterialItemSmall__LessonTitle__p6sIT {\n  font-size: 13px;\n  color: rgba(0, 32, 51, 0.6);\n  font-weight: bold;\n  margin: 0;\n  margin-top: 10px;\n}\n.MaterialItem_MaterialItemSmall__Title__Qc\\+dT {\n  color: rgba(0, 32, 51, 0.6);\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/MaterialItem/MaterialItem.module.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,eAAA;AACJ;AAAI;EACI,mBAAA;AAER;AAAI;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AAER;AADQ;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAGZ;AAAI;EACI,eAAA;EACA,2BAAA;EACA,iBAAA;EACA,SAAA;EACA,gBAAA;AAER;AAAI;EAEI,2BAAA;EACA,SAAA;AACR","sourcesContent":[".MaterialItemSmall {\n    border-bottom: solid 1px #F5F7FB;\n    cursor: pointer;\n    &:hover {\n        background: #F5F7FB;\n    }\n    &__Image {\n        width: 100%;\n        height: 75px;\n        overflow: hidden;\n        img {\n            width: 100%;\n            height: 100%;\n            object-fit: cover;\n        }\n    }\n    &__LessonTitle {\n        font-size: 13px;\n        color: #00203399;\n        font-weight: bold;\n        margin: 0;\n        margin-top: 10px;\n    }\n    &__Title {\n\n        color: #00203399;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MaterialItemSmall": "MaterialItem_MaterialItemSmall__wh3bm",
	"MaterialItemSmall__Image": "MaterialItem_MaterialItemSmall__Image__Uh7CN",
	"MaterialItemSmall__LessonTitle": "MaterialItem_MaterialItemSmall__LessonTitle__p6sIT",
	"MaterialItemSmall__Title": "MaterialItem_MaterialItemSmall__Title__Qc+dT"
};
export default ___CSS_LOADER_EXPORT___;
