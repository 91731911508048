import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import { getAllServices } from '../../api/ServicesApi';
import { useApi } from '../../hooks/useApi';
import { CourseItemInSidebar } from '../CourseItem/CourseItemInSidebar';
import { OneServiceInformationBlock } from '../OneServiceInformationBlock/OneServiceInformationBlock';

export const OneServiceSidebar = ({ service }) => {
  const [additionalServices, setAdditionalServices] = useState([]);

  return (
    <>
      <OneServiceInformationBlock service={service} />
      <Text as="h3" size="xl" style={{ color: 'rgba(0, 32, 51, 0.6)' }}>
        Похожие услуги
      </Text>
      {/* {additionalServices.map((item) => (
        <CourseItemInSidebar item={item} slice={50} />
      ))} */}
    </>
  );
};
