import axios from 'axios';
import { ENV } from '../utils/env';
import { getMe } from './UserApi';
import Cookies from 'js-cookie';

export const sendNotification = async (data) => {
  try {
    const token = Cookies.get('jwt');
    let to;
    if (token) {
      const me = await getMe();
      to = me.email;
    } else {
      to = data.to;
    }

    if (to) {
      let config = {
        method: 'post',
        url: `${ENV.BACKEND_URL}/notification/send`,
        data: {
          ...data,
          to: to,
        },
      };

      const resultData = await axios(config);
      return resultData.data;
    }
  } catch (error) {
    throw error;
  }
};
