// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlertsList_AlertsList__Ae7Ms {\n  position: fixed;\n  left: 15px;\n  bottom: 0;\n  width: 100%;\n  max-width: 350px;\n  z-index: 9999;\n}\n.AlertsList_AlertsList__Item__neHPl {\n  margin-bottom: 15px;\n}", "",{"version":3,"sources":["webpack://./src/components/Default/AlertsList/AlertsList.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAAI;EACI,mBAAA;AAER","sourcesContent":[".AlertsList {\n    position: fixed;\n    left: 15px;\n    bottom: 0;\n    width: 100%;\n    max-width: 350px;\n    z-index: 9999;\n    &__Item {\n        margin-bottom: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlertsList": "AlertsList_AlertsList__Ae7Ms",
	"AlertsList__Item": "AlertsList_AlertsList__Item__neHPl"
};
export default ___CSS_LOADER_EXPORT___;
