import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import styles from './MaterialAboutTesting.module.scss';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';

export const MaterialAboutTesting = ({ text }) => {
  const [isTruncate, setIsTruncate] = useState(true);
  const [normalText, setNormalText] = useState();
  const [truncateText, setTruncateText] = useState();

  useEffect(() => {
    setNormalText(text);
    setTruncateText(text?.slice(0, 150));
  }, [text]);
  return (
    <Card
      verticalSpace="m"
      horizontalSpace="m"
      shadow={false}
      border={false}
      form="round"
      className={styles.MaterialAboutTesting}
    >
      <Text as="h4" size="2xl">
        О тестировании
      </Text>
      <div
        dangerouslySetInnerHTML={{
          __html: isTruncate ? truncateText : normalText,
        }}
      ></div>

      <Button
        label={isTruncate ? 'Показать больше' : 'Показать меньше'}
        view="clear"
        iconRight={isTruncate ? IconArrowDown : IconArrowUp}
        style={{ marginLeft: 'auto', display: 'flex' }}
        onClick={() => setIsTruncate(!isTruncate)}
      />
    </Card>
  );
};
