import { TextField } from '@consta/uikit/TextField';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../../../utils/urls.util';
import styles from './Form.module.scss';
import { useApi } from '../../../hooks/useApi';
import { resetPassword } from '../../../api/UserApi';
import { AlertsList } from '../AlertsList/AlertsList';

export const ResetWithoutLoginForm = () => {
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const resetPasswordApi = useApi(resetPassword);
  const [alerts, setAlerts] = useState([]);
  const navigate = useNavigate();
  const { hash } = useParams();
  const resetPasswordHandler = async () => {
    await resetPasswordApi.sendRequest(hash, {
      password,
    });
    setAlerts([
      {
        text: 'Пароль успешно изменен!',
        type: 'success',
      },
    ]);
    setPassword('');
    setPasswordAgain('');
    navigate(URLS.LOGIN);
  };
  return (
    <>
      <AlertsList alerts={alerts} />
      <TextField
        placeholder="Новый пароль"
        label="Новый пароль"
        width="full"
        type="password"
        value={password}
        style={{ marginTop: '30px' }}
        onChange={({ value }) => {
          setPassword(value);
        }}
      />
      <TextField
        placeholder="Новый пароль еще раз"
        label="Новый пароль еще раз"
        width="full"
        type="password"
        value={passwordAgain}
        style={{ marginBottom: '10px', marginTop: '10px' }}
        onChange={({ value }) => {
          setPasswordAgain(value);
        }}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={!password || !passwordAgain || password !== passwordAgain}
        sx={{ mt: 2 }}
        onClick={resetPasswordHandler}
      >
        Изменить пароль
      </Button>
    </>
  );
};
