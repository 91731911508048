import { Alert, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeAlert } from '../../../store/alert.store';
import styles from './AlertsList.module.scss';
export const AlertsList = ({ alerts }) => {
  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState(false);
  const openDetailHandler = () => {
    setOpenDetail(!openDetail);
  };

  return (
    <div className={styles.AlertsList}>
      {alerts?.map((alert, index) => (
        <Alert
          key={index}
          severity={alert.type}
          color={alert.type}
          className={styles.AlertsList__Item}
          onClose={() => dispatch(removeAlert({ ...alert, index }))}
        >
          {alert.text}
          {alert.message && (
            <span style={{ display: 'block', margin: '5px 0' }}>
              <i>{alert.message}</i>
            </span>
          )}
          <br />
          {alert.details && alert.message && (
            <>
              {openDetail && (
                <span style={{ display: 'block', margin: '5px 0' }}>
                  <i>{alert.details}</i>
                </span>
              )}
              <Button onClick={openDetailHandler} color={alert.type}>
                {openDetail ? 'Свернуть' : 'Подробнее'}
              </Button>
            </>
          )}
        </Alert>
      ))}
    </div>
  );
};
