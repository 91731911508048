import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { Devider } from '../Default/Devider/Devider';
import styles from './ServiceItem.module.scss';
import notFoundImg from '../../assets/images/no-image.png';

const ServiceLabels = () => {
  const labels = [
    {
      type: 'info',
      text: 'Пройден',
    },
  ];
  return (
    <div className={styles.ServiceItem__Labels}>
      {labels?.map((l) => (
        <Card verticalSpace="xs" horizontalSpace="xs">
          {l.text}
        </Card>
      ))}
    </div>
  );
};
export const ServiceItem = ({ service }) => {
  const [media, setMedia] = useState({});
  const [course, setCourse] = useState({});

  useEffect(() => {
    setMedia(service?.file[0]);
    setCourse(service?.course);
  }, []);
  return (
    <Card className={styles.ServiceItem}>
      <div className={styles.ServiceItem__Thumbnail}>
        <div className={styles.ServiceItem__Hoverblock}>
          <Link to={URLS.SERVICES + '/' + service.id}>
            <Button view="primary" label="Подробнее" />
          </Link>
        </div>
        <img src={service?.imageUrl || notFoundImg} />
      </div>
      <div className={styles.ServiceItem__Content}>
        <Link
          to={URLS.SERVICES + '/' + service.id}
          className={styles.ServiceItem__Title}
        >
          <Text as="p" view="h4" size="l">
            {service?.title}
          </Text>
        </Link>
        <Devider style={{ margin: '10px 0' }} />
        <span
          style={{ fontSize: '14px' }}
          className={styles.ServiceItem__Caption}
        >
          Для доступа к услуге необходимо пройти курс{' '}
          <Link to={'/education/' + course?.id}>
            {course?.title?.toLowerCase()}
          </Link>
        </span>
      </div>
    </Card>
  );
};
