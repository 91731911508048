import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@consta/uikit/RadioGroup';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';
import { Button } from '@consta/uikit/Button';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { IconArrowLeft } from '@consta/uikit/IconArrowLeft';
import { Informer } from '@consta/uikit/Informer';
import { List, ListBox } from '@consta/uikit/ListCanary';

import styles from './QuestionsList.module.scss';
import { TestResult } from '../TestResult/TestResult';

import { useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { editTestResult } from '../../api/TestApi';
import {
  createMaterialResult,
  getMaterialsResultCount,
  getMaterialsStatus,
} from '../../api/MaterialApi';
import { createCourseResult } from '../../api/CourseApi';

import { add, format } from 'date-fns';

export const QuestionsList = ({
  nextMaterial,
  closeTest,
  test,
  material,
  course,
  closeQuestions,
  isMaterialTest,
  hasCert,
}) => {
  let [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [value, setValue] = useState();
  const [isFinish, setIsFinish] = useState(false);
  const [isMaterialTestFinish, setIsMaterialTestFinish] = useState(false);
  const [finishData, setFinishData] = useState({});
  const [questionResult, setQuestionResult] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [notFullQuestions, setNotFullQuestions] = useState(true);
  const [result, setResult] = useState([]);
  const materilResultCountApi = useApi(getMaterialsStatus);

  const editTestResultApi = useApi(editTestResult);
  const createMaterialResultApi = useApi(createMaterialResult);
  const createCourseResultApi = useApi(createCourseResult);

  const { courseId, materialId, testId } = useParams();
  useEffect(() => {
    setQuestions(test?.questions);
  }, [test]);

  useEffect(() => {
    if (value?.length) {
      countResult(value);
      setDisabledButton(false);
    } else {
      if (value) {
        countResult([value]);
      } else {
        setDisabledButton(true);
      }
    }
  }, [value]);

  useEffect(() => {
    if (
      questionResult?.find((q) => q.index === currentQuestionIndex)?.answers
        ?.length
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
    if (questionResult?.length !== questions?.length) {
      setNotFullQuestions(true);
    } else {
      setNotFullQuestions(false);
    }
  }, [questionResult]);

  const nextStep = () => {
    let newIndex =
      currentQuestionIndex + 1 < questions?.length - 1
        ? currentQuestionIndex + 1
        : questions?.length - 1;
    setCurrentQuestionIndex(newIndex);
  };
  const prevStep = () => {
    let newIndex = currentQuestionIndex - 1 >= 0 ? currentQuestionIndex - 1 : 0;
    setCurrentQuestionIndex(newIndex);
  };
  const countResult = (value) => {
    if (value) {
      for (const item of value) {
        const a = questions[currentQuestionIndex]?.answers?.find(
          (a) => a.answer === item?.answer,
        );
        const q = questions[currentQuestionIndex];

        if (test?.containsDirections) {
          setResult([
            ...result,
            +q.questionWeight *
              +q?.direction?.directionWeight *
              +item?.answerWeight,
          ]);
        } else {
          let newResults = [];

          const checkAnswer = result?.find(
            (r) => r.questionId == questions[currentQuestionIndex]?.id,
          );
          if (checkAnswer?.id) {
            newResults = [
              ...result?.filter((r) => r.questionId !== checkAnswer.questionId),
              {
                ...item,
                result: item.isRight,
                questionId: questions[currentQuestionIndex]?.id,
              },
            ];
          } else {
            newResults = [
              ...result,
              {
                ...item,
                result: item.isRight,
                questionId: questions[currentQuestionIndex]?.id,
              },
            ];
          }

          setResult(newResults);
        }
      }
      const qResult = questionResult?.filter(
        (q) => q.index !== currentQuestionIndex,
      );

      setQuestionResult([
        ...qResult,
        {
          index: currentQuestionIndex,
          question: questions[currentQuestionIndex]?.question,
          answers: value,
          result: test?.containsDirections
            ? result?.reduce((a, b) => a + b)
            : result?.filter((r) => r.result !== true)?.length
            ? false
            : true,
        },
      ]);
    } else {
      const qResult = questionResult?.filter(
        (q) => q.index !== currentQuestionIndex,
      );
      setQuestionResult([...qResult]);
    }
  };

  const finishTest = async () => {
    const correctUserCount = questionResult?.filter((q) => q.result)?.length;

    let containsDirectionsData = [];
    let containsDirectionsTitle = '';
    let checkTestResult;
    if (test?.containsDirections) {
      for (const i in test?.resultDirection) {
        if (i == 'notEnough') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Недостаточный.',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'veryLow') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Очень низкий.',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'low') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Низкий',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'belowTheAverage') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Ниже среднего.',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'average') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Средний',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'aboveAverage') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Выше среднего.',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'high') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Высокий.',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        } else if (i == 'veryHigh') {
          containsDirectionsData.push({
            title: 'Результат прохождения теста - Очень высокий.',
            score: test?.resultDirection[i].score,
            isResult: test?.resultDirection[i].isResult,
          });
        }
      }

      let delta = 999;
      let itemId = -1;
      const testResult = questionResult[questionResult?.length - 1]?.result;
      for (const key in containsDirectionsData) {
        if (Math.abs(containsDirectionsData[key].score - testResult) < delta) {
          itemId = key;
          delta = Math.abs(containsDirectionsData[key].score - testResult);
        }
      }

      containsDirectionsTitle = containsDirectionsData[itemId].title;

      if (
        testResult > containsDirectionsData[itemId].score &&
        containsDirectionsData[itemId]?.isResult
      ) {
        checkTestResult = testResult > containsDirectionsData[itemId].score;
      }
    } else {
      checkTestResult = correctUserCount >= +test?.correctCounter;
    }

    if (checkTestResult) {
      setFinishData({
        success: true,
        title: containsDirectionsTitle || test?.successTitle,
        description: test?.successDescription,
        successActionText: test?.successActionText,
        successActionUrl: test?.successActionUrl || '#!',
      });
      const testResult = await editTestResultApi.sendRequest({
        test,
        material,
        course,
        result: true,
        status: 'finished',
        questionResult,
        canGenerateCertificate: hasCert && true,
      });

      if (materialId) {
        const materialResultData = await createMaterialResultApi.sendRequest({
          test,
          material,
          course,
          result: true,
          status: 'finished',
        });
      }

      if (course?.settingsForCourseResult === 'materialsResult') {
        const materialResultCount = await materilResultCountApi.sendRequest({
          course,
        });
        const count = materialResultCount?.filter(
          (item) => item.status === 'finished',
        )?.length;

        const courseResultData = await createCourseResultApi.sendRequest({
          status: count >= course?.materials?.length ? 'finished' : 'failed',
          result: count >= course?.materials?.length ? true : false,
          course,
          material,
          test,
        });
      } else if (course?.settingsForCourseResult === 'courseResult') {
        createCourseResultApi.sendRequest({
          status:
            correctUserCount >= +test?.correctCounter ? 'finished' : 'failed',
          result: correctUserCount >= +test?.correctCounter ? true : false,
          course,
          material,
          test,
        });
      } else if (
        course?.settingsForCourseResult === 'materialAndCourseResult'
      ) {
        if (materialId) {
          createMaterialResultApi
            .sendRequest({
              test,
              material,
              course,
              result: true,
              status: 'finished',
            })
            .then((count) => {
              createCourseResultApi.sendRequest({
                status:
                  count >= course?.materials?.length &&
                  correctUserCount >= +test?.correctCounter
                    ? 'finished'
                    : 'failed',
                result:
                  count >= course?.materials?.length &&
                  correctUserCount >= +test?.correctCounter
                    ? true
                    : false,
                course,
                material,
                test,
              });
            });
        }
      }

      let expireDate = format(new Date(), 'yyyy-MM-dd');
      if (course?.tryAgainSettings == 'year') {
        expireDate = format(add(new Date(), { months: 12 }), 'yyyy-MM-dd');
      } else if (course?.tryAgainSettings == 'month') {
        expireDate = format(add(new Date(), { days: 30 }), 'yyyy-MM-dd');
      } else if (course?.tryAgainSettings == 'week') {
        expireDate = format(add(new Date(), { days: 7 }), 'yyyy-MM-dd');
      } else if (course?.tryAgainSettings == 'day') {
        expireDate = format(add(new Date(), { hours: 24 }), 'yyyy-MM-dd');
      }
    } else {
      setFinishData({
        success: false,
        title: containsDirectionsTitle || test?.badTitle,
        description: test?.badDescription,
        badActionText: test?.badActionText,
        badActionUrl: test?.badActionUrl || '#!',
        id: test.id,
      });
      editTestResultApi.sendRequest({
        test,
        material,
        course,
        result: false,
        status: 'failed',
        questionResult,
        canGenerateCertificate: hasCert && false,
      });
    }

    setIsFinish(true);
  };

  return (
    <>
      {!isFinish && !isMaterialTestFinish && (
        <Grid cols="12">
          <GridItem
            col="6"
            breakpoints={{
              xs: {
                col: 12,
              },
              s: {
                col: 12,
              },
              l: {
                col: 6,
              },
            }}
          >
            <Text
              as="h1"
              view="h1"
              size="3xl"
              dangerouslySetInnerHTML={{ __html: test?.title }}
            ></Text>
            <Card
              verticalSpace="2xl"
              horizontalSpace="2xl"
              border={true}
              shadow={false}
              style={{ background: '#F5F7FB' }}
            >
              <Text size="l" as="h3">
                {questions[currentQuestionIndex]?.question}
              </Text>
              {questions[currentQuestionIndex]?.answers?.length && (
                <>
                  {questions[currentQuestionIndex]?.answers?.filter(
                    (a) => a.isRight,
                  )?.length > 1 ? (
                    <>
                      <CheckboxGroup
                        value={
                          questionResult?.find(
                            (q) => q.index === currentQuestionIndex,
                          )?.answers
                        }
                        items={questions[currentQuestionIndex]?.answers}
                        getItemLabel={(item) => item.answer}
                        onChange={({ value }) => countResult(value)}
                        name={'CheckboxGroup'}
                        className={styles.QuestionsList__Aswer}
                      />
                    </>
                  ) : (
                    <RadioGroup
                      value={
                        questionResult?.find(
                          (q) => q.index === currentQuestionIndex,
                        )?.answers[0]
                      }
                      items={questions[currentQuestionIndex]?.answers}
                      getItemLabel={(item) => item.answer}
                      onChange={({ value }) => countResult([value])}
                      className={styles.QuestionsList__Aswer}
                    />
                  )}
                </>
              )}
            </Card>
          </GridItem>
          <GridItem col="3"></GridItem>
          <GridItem
            col="3"
            breakpoints={{
              xs: {
                col: 12,
              },
              s: {
                col: 12,
              },
              l: {
                col: 3,
              },
            }}
          >
            <div className={styles.QuestionsList__Sidebar}>
              <ListBox className={styles.QuestionsList__ListBox} shadow border>
                <List
                  placeholder="Выберите вариант"
                  items={questions.map((q, i) => ({
                    label: q.question,
                    id: i,
                    disabled: i === currentQuestionIndex || disabledButton,
                    checked: i === currentQuestionIndex,
                    status: false,
                    onClick: () => setCurrentQuestionIndex(i),
                  }))}
                />
              </ListBox>
              <div className={styles.QuestionsList__Pagination}>
                <Button
                  label="Предыдущий вопрос"
                  view="ghost"
                  iconLeft={IconArrowLeft}
                  onlyIcon
                  form="round"
                  disabled={currentQuestionIndex === 0}
                  onClick={() => prevStep()}
                />
                <Text as="p" view="p" size="m">
                  {currentQuestionIndex + 1} вопрос из {questions?.length || 0}
                </Text>
                <Button
                  label="Следующий вопрос"
                  view="ghost"
                  iconLeft={IconArrowRight}
                  onlyIcon
                  form="round"
                  disabled={
                    currentQuestionIndex === questions?.length - 1 ||
                    disabledButton
                  }
                  onClick={() => nextStep()}
                />
              </div>

              {currentQuestionIndex === questions?.length - 1 ? (
                <>
                  {notFullQuestions && (
                    <Informer
                      label="Вы ответили не на все вопросы"
                      view="bordered"
                      status="alert"
                      className={styles.QuestionsList__Informer}
                    />
                  )}

                  <Button
                    label="Завершить тест"
                    width="full"
                    color="sucess"
                    disabled={disabledButton}
                    onClick={() => finishTest()}
                  />
                </>
              ) : (
                <Button
                  label="Продолжить"
                  width="full"
                  disabled={disabledButton}
                  onClick={() => nextStep()}
                />
              )}
            </div>
          </GridItem>
        </Grid>
      )}

      {isFinish && (
        <TestResult
          title={finishData?.title}
          description={finishData?.description}
          success={finishData?.success}
          successActionText={finishData?.successActionText}
          successActionUrl={finishData?.successActionUrl}
          badActionText={finishData?.badActionText}
          badActionUrl={finishData?.badActionUrl}
          nextMaterial={nextMaterial}
          course={course}
          test={test}
        />
      )}
    </>
  );
};
