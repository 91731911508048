// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OneServiceContent_OneServiceContent__EUMzZ {\n  padding-top: 40px;\n  padding-bottom: 40px;\n}", "",{"version":3,"sources":["webpack://./src/components/OneServiceContent/OneServiceContent.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,oBAAA;AACJ","sourcesContent":[".OneServiceContent {\n    padding-top: 40px;\n    padding-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OneServiceContent": "OneServiceContent_OneServiceContent__EUMzZ"
};
export default ___CSS_LOADER_EXPORT___;
