import { TextField } from '@consta/uikit/TextField';
import React, { useEffect } from 'react';

export const ResetPasswordForm = ({ user, changeState }) => {
  return (
    <>
      <TextField
        placeholder="Старый пароль"
        label="Старый пароль"
        width="full"
        type="password"
        value={user?.oldPassword}
        style={{ marginBottom: '10px' }}
        onChange={({ value }) => {
          changeState({ ...user, oldPassword: value });
        }}
      />

      <TextField
        placeholder="Новый пароль:"
        label="Новый пароль:"
        width="full"
        type="password"
        value={user?.password}
        style={{ marginBottom: '10px' }}
        onChange={({ value }) => {
          changeState({ ...user, password: value });
        }}
      />
      <TextField
        placeholder="Новый пароль еще раз"
        label="Новый пароль еще раз"
        width="full"
        type="password"
        value={user?.newPasswordAgain}
        style={{ marginBottom: '10px' }}
        onChange={({ value }) => {
          changeState({ ...user, newPasswordAgain: value });
        }}
      />
    </>
  );
};
