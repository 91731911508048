import axios from 'axios';
import Cookies from 'js-cookie';
import { ENV } from '../utils/env';

export const getCheckUserMaturityTest = async ({ token, testId }) => {
  try {
    const formdata = new FormData();
    formdata.append('token', token);
    formdata.append('test_id', testId);

    let config = {
      method: 'post',
      url: `${ENV.API_CUSTOM_URL}/user/check-test`,
      data: formdata,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
