import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React from 'react';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';

export const ServicesMainScreen = ({ settings }) => {
  return (
    <BlockContainer style={{ paddingTop: '48px' }}>
      <Grid cols="12" gap="3xl">
        <GridItem
          col="6"
          breakpoints={{
            xs: {
              col: 9,
            },
            s: {
              col: 9,
            },
            l: {
              col: 6,
            },
          }}
        >
          <Text as="h1" view="h1" size="3xl">
            Услуги Центра поддержки экспорта
          </Text>
          <Text as="p" view="p">
            {settings?.find((s) => s.key == 'servicesPageText')?.value}
          </Text>
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};
