import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import notFoundImg from '../../assets/images/no-image.png';
import { URLS } from '../../utils/urls.util';
import styles from './CourseItem.module.scss';

export const CourseItemInSidebar = ({ item, slice = 27 }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (item?.title?.length > +slice) {
      setTitle(item.title?.slice(0, +slice) + '...');
    } else {
      setTitle(item?.title);
    }
  }, [item]);

  return (
    <Grid cols="12" gap="l" yAlign="center" style={{ marginBottom: '16px' }}>
      <GridItem col="4">
        <Card
          shadow={false}
          border={true}
          className={styles.CourseItemInSidebar__SiderarImage}
        >
          <a href={item?.externalLink} target="blank">
            <img src={item?.imageUrl || notFoundImg} />
          </a>
        </Card>
      </GridItem>
      <GridItem col="8">
        {!!item && item?.externalLink ? (
          <a
            href={item?.externalLink}
            className={styles.CourseItemInSidebar__Title}
            target="blank"
          >
            <Text as="span">{title}</Text>
          </a>
        ) : (
          <Link
            to={URLS.COURSES_PAGE + '/' + item?.id}
            className={styles.CourseItemInSidebar__Title}
          >
            <Text as="span">{title}</Text>
          </Link>
        )}
      </GridItem>
    </Grid>
  );
};
