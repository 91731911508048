import React, { useEffect, useState } from 'react';
import { ProgressStepBar } from '@consta/uikit/ProgressStepBar';
import styles from './OneServiceProgressBar.module.scss';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { IconAllDone } from '@consta/uikit/IconAllDone';
import { Button } from '@consta/uikit/Button';
import { useApi } from '../../hooks/useApi';
import { getAllSettings } from '../../api/SettingsApi';
import { getCourseStatus } from '../../api/CourseApi';
import { getTestResult, getTestStatus } from '../../api/TestApi';
import { getCusomerPermissions } from '../../api/UserApi';
import { checkAllow } from '../../api/ServicesApi';

export const OneServiceProgressBar = ({ service }) => {
  const [checkResult, setCheckResult] = useState({});
  const [settings, setSettings] = useState({});
  const settingsApi = useApi(getAllSettings);
  const courseStatusApi = useApi(getCourseStatus);
  const permissionsApi = useApi(getCusomerPermissions);
  const checkAllowApi = useApi(checkAllow);

  useEffect(() => {
    settingsApi.sendRequest().then((result) => setSettings(result));
  }, [service]);

  useEffect(() => {
    const sendRequests = async () => {
      const result = await checkAllowApi.sendRequest({
        course: { id: service?.course?.id },
      });

      setCheckResult(result);
    };
    if (service?.course?.id) {
      sendRequests();
    }
  }, [service]);
  return (
    <Card
      verticalSpace="l"
      horizontalSpace="l"
      border={false}
      shadow={false}
      className={styles.OneServiceProgressBar}
    >
      <Text as="h3" size="xl" style={{ margin: '30px 0', marginTop: 0 }}>
        Для получения услуги требуется
      </Text>
      <div className={styles.OneServiceProgressBar__Checkboxs}>
        <div className={styles.OneServiceProgressBar__Checkboxs__Item}>
          <IconAllDone
            style={{ marginRight: '10px' }}
            view={checkResult?.maturityTest ? 'success' : 'alert'}
          />
          <Text
            className={
              checkResult?.maturityTest
                ? ''
                : styles.OneServiceProgressBar__Checkboxs__Item__TextOpacity
            }
          >
            Тест на оценку экспортной зрелости
          </Text>
        </div>

        <div className={styles.OneServiceProgressBar__Checkboxs__Item}>
          <IconAllDone
            style={{ marginRight: '10px' }}
            view={checkResult?.course ? 'success' : 'alert'}
          />
          <Text
            className={
              checkResult?.course
                ? ''
                : styles.OneServiceProgressBar__Checkboxs__Item__TextOpacity
            }
          >
            Завершить курс - {service?.course?.title}
          </Text>
        </div>
        <div className={styles.OneServiceProgressBar__Checkboxs__Item}>
          <IconAllDone
            style={{ marginRight: '10px' }}
            view={checkResult?.finalTest ? 'success' : 'alert'}
          />
          <Text
            className={
              checkResult?.finalTest
                ? ''
                : styles.OneServiceProgressBar__Checkboxs__Item__TextOpacity
            }
          >
            Финальное итоговое тестирование
          </Text>
        </div>
      </div>

      <Button
        label="Получить услугу"
        disabled={Object.values(checkResult).some((r) => r === false)}
        style={{ marginTop: '30px' }}
        href={service?.externalLink}
        as="a"
        target="blank"
      />
    </Card>
  );
};
