import React, { useEffect, useRef, useState } from 'react';
import {
  Header,
  HeaderLogo,
  HeaderMenu,
  HeaderModule,
} from '@consta/uikit/Header';
import { Button } from '@consta/uikit/Button';
import { IconFlagFilled } from '@consta/uikit/IconFlagFilled';
import { IconExit } from '@consta/uikit/IconExit';
import { IconHome } from '@consta/uikit/IconHome';
import { IconVideo } from '@consta/uikit/IconVideo';
import { IconWorldFilled } from '@consta/uikit/IconWorldFilled';
import { IconSpeed } from '@consta/uikit/IconSpeed';
import { IconUser } from '@consta/uikit/IconUser';
import { IconPhone } from '@consta/uikit/IconPhone';
import { Breadcrumbs } from '@consta/uikit/Breadcrumbs';

import { User } from '@consta/uikit/User';
import { ContextMenu } from '@consta/uikit/ContextMenu';

import { Link, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '@consta/uikit/Grid';

import logoIMG from '../../../assets/images/logo.png';
import { URLS } from '../../../utils/urls.util';
import styles from './MainHeader.module.scss';
import { getAllSettings } from '../../../api/SettingsApi';
import { useApi } from '../../../hooks/useApi';
import { useCookies } from 'react-cookie';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { FeedbackModal } from '../../FeedbackModal/FeedbackModal';
import { AlertsList } from '../AlertsList/AlertsList';
import { getMe } from '../../../api/UserApi';
export const MainHeader = ({
  auth,
  breadcrumbs,
  hideMenu,
  settings,
  permissions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { height, width } = useWindowDimensions();
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const [alerts, setAlerts] = useState([]);
  const [group, setGroup] = useState([]);
  const [items, setItems] = useState([]);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [user, setUser] = useState({});
  const getMeApi = useApi(getMe);

  const navigate = useNavigate();
  useEffect(() => {
    if (cookies.jwt) {
      getMeApi.sendRequest().then((result) => setUser(result));
    }
  }, [cookies.jwt]);
  useEffect(() => {
    if (settings?.length) {
      if (width < 1280) {
        setGroup([
          {
            name: 'Профиль',

            key: 1,
          },
          {
            name: 'Действия',
            key: 2,
          },
          {
            name: 'Меню',
            key: 3,
          },
          {
            name: 'Тесты',
            key: 4,
          },
        ]);

        setItems([
          {
            label: 'Редактировать',
            iconLeft: IconUser,
            group: 1,
            as: 'a',
            attributes: { href: URLS?.PROFILE },
          },

          {
            label: 'Главная',
            iconLeft: IconHome,
            group: 3,
            as: 'a',
            attributes: {
              href: URLS.HOME,
            },
          },
          {
            label: 'Тест на экспортную зрелость',
            iconLeft: IconSpeed,
            group: 4,
            as: 'a',
            attributes: {
              href:
                '/single-test/' +
                settings?.find((s) => s.key == 'maturityTestId')?.value,
            },
          },
          {
            label: 'Финальное тестирование',
            iconLeft: IconFlagFilled,
            group: 4,
            as: 'a',
            attributes: {
              href:
                '/single-test/' +
                settings?.find((s) => s.key == 'finalTest')?.value,
            },
          },
          {
            label: 'Курсы',
            iconLeft: IconVideo,
            group: 3,
            as: 'a',
            attributes: {
              href: URLS.COURSES_PAGE,
            },
          },
          {
            label: 'Услуги',
            iconLeft: IconWorldFilled,
            group: 3,
            as: 'a',
            attributes: {
              href: URLS.SERVICES,
            },
          },

          {
            label: 'Выход',
            iconLeft: IconExit,
            group: 2,
            onClick: () => logout(),
          },
        ]);
      } else {
        setGroup([
          {
            name: 'Профиль',
            key: 1,
          },
          {
            name: 'Действия',
            key: 2,
          },
        ]);
        setItems([
          {
            label: 'Редактировать',
            iconLeft: IconUser,
            group: 1,
            as: 'a',
            attributes: { href: URLS?.PROFILE },
          },
          {
            label: 'Выход',
            iconLeft: IconExit,
            group: 2,
            onClick: () => logout(),
          },
        ]);
      }
    }
  }, [settings, width]);
  const ref = useRef(null);
  const menuItems = [
    {
      label: 'Главная',
      href: URLS.HOME,
    },
    {
      label: 'Курсы',
      href: URLS.COURSES_PAGE,
    },
    {
      label: 'Услуги',
      href: URLS.SERVICES,
    },
    {
      label: 'Профиль',
      href: URLS.PROFILE,
    },
  ];

  const logout = () => {
    removeCookie('jwt');
    navigate(URLS.LOGIN);
  };

  const sortGroup = (a, b) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };
  const renderLeftSide = (item) => {
    const Icon = item.iconLeft;
    return <Icon size="s" />;
  };

  return (
    <Grid cols={1} className={styles.MainHeader}>
      <GridItem>
        <Header
          leftSide={
            <>
              <HeaderModule indent="l">
                <HeaderLogo>
                  <Link to={URLS.HOME}>
                    <img src={logoIMG} style={{ width: '224px' }} />
                  </Link>
                </HeaderLogo>
              </HeaderModule>

              <HeaderModule indent="l" className={styles.Header__Menu}>
                {auth && <HeaderMenu items={menuItems} />}
              </HeaderModule>
            </>
          }
          rightSide={
            <>
              {auth && (
                <HeaderModule indent="s" className={styles.Header__Menu}>
                  {settings?.length && (
                    <>
                      <Button
                        size="s"
                        view="clear"
                        form="round"
                        label="Тест на экспортную зрелость"
                        iconLeft={IconSpeed}
                        as="a"
                        href={
                          '/single-test/' +
                          settings?.find((s) => s.key == 'maturityTestId')
                            ?.value
                        }
                      />
                      <Button
                        size="s"
                        view="clear"
                        form="round"
                        label="Финальный опрос"
                        iconLeft={IconFlagFilled}
                        as="a"
                        href={
                          '/single-test/' +
                          settings?.find((s) => s.key == 'finalTest')?.value
                        }
                      />
                    </>
                  )}
                </HeaderModule>
              )}
              <HeaderModule indent="s" className={styles.Header__Menu}>
                {auth ? (
                  <Button
                    size="s"
                    view={auth ? 'clear' : 'ghost'}
                    form="round"
                    label="Обратная связь"
                    as="a"
                    onClick={() => setOpenFeedbackModal(true)}
                    iconLeft={IconPhone}
                  />
                ) : (
                  <Button
                    size="s"
                    view={auth ? 'clear' : 'ghost'}
                    form="round"
                    label="Основной сайт"
                    as="a"
                    href="https://spbexport.ru/"
                    target="blank"
                  />
                )}
              </HeaderModule>
              <AlertsList alerts={alerts} />
              {openFeedbackModal && (
                <FeedbackModal
                  isOpen={openFeedbackModal}
                  closeHandler={() => setOpenFeedbackModal(false)}
                  openAlertHandler={(data) =>
                    setAlerts([
                      {
                        text: 'Сообщение успещно отправлено! Мы свяжимся с вами в ближайшее время',
                        type: 'success',
                      },
                    ])
                  }
                />
              )}

              {auth && (
                <HeaderModule indent="l">
                  <User
                    withArrow
                    size="l"
                    onlyAvatar
                    name={
                      user?.firstName && user?.lastName
                        ? user?.firstName + ' ' + user?.lastName
                        : user?.email
                    }
                    ref={ref}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: 'pointer' }}
                  />
                  <ContextMenu
                    items={items}
                    isOpen={isOpen}
                    groups={group}
                    getItemGroupId={(item) => item.group}
                    getGroupLabel={(group) => group.name}
                    getGroupId={(group) => group.key}
                    anchorRef={ref}
                    getItemLeftSide={renderLeftSide}
                    sortGroup={sortGroup}
                  />
                </HeaderModule>
              )}
            </>
          }
        />
      </GridItem>
      {breadcrumbs && (
        <GridItem>
          <Breadcrumbs
            fitMode="dropdown"
            size="s"
            items={breadcrumbs}
            className={styles.MainHeader__Breadcrumbs}
          />
        </GridItem>
      )}
    </Grid>
  );
};
