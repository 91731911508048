import { PDFViewer } from '@react-pdf/renderer';
import { format } from 'date-fns';
import React from 'react';
import { CertificatePDF } from '../../components/CertificatePDF/CertificatePDF';

export const DocsPage = () => {
  return (
    <PDFViewer>
      <CertificatePDF
        cert={{
          test_title: 'Финальный тест',
          expire_date: format(new Date(), 'dd.MM.yyyy'),
          company_inn: '519001127905',
        }}
      />
    </PDFViewer>
  );
};
