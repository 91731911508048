import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React, { useEffect } from 'react';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { FinalTest } from '../FinalTest/FinalTest';
import { MaterialSmallList } from '../MaterialList/MaterialSmallList';
import { OneCourseSidebar } from '../OneCourseSidebar/OneCourseSidebar';
import styles from './OneCourseGrid.module.scss';
export const OneCourseGrid = ({ course }) => {
  return (
    <BlockContainer style={{ paddingTop: '48px' }}>
      <Grid cols="12" gap="3xl">
        <GridItem
          col="9"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 12,
            },
            l: {
              col: 9,
            },
          }}
        >
          <Grid cols="12">
            <GridItem
              col="9"
              breakpoints={{
                xs: {
                  col: 10,
                },
                s: {
                  col: 10,
                },
                l: {
                  col: 9,
                },
              }}
            >
              {course?.result?.status === 'complete' && (
                <>
                  <div className={styles.OneCourseGrid__Status}>Пройден</div>
                  <div className={styles.OneCourseGrid__ResultDate}>
                    {course?.result?.updated}
                  </div>
                </>
              )}
              <Text as="h2" size="4xl" style={{ marginTop: '10px' }}>
                {course?.title}
              </Text>
              <Text>{course?.shortDescription}</Text>
            </GridItem>
          </Grid>
          <Grid cols="12" gap="3xl" style={{ marginTop: '48px' }}>
            <GridItem
              col="4"
              breakpoints={{
                xs: {
                  col: 12,
                },
                s: {
                  col: 12,
                },
                l: {
                  col: 4,
                },
              }}
            >
              <MaterialSmallList course={course} status={course?.result} />
            </GridItem>
            <GridItem
              col="8"
              breakpoints={{
                xs: {
                  col: 12,
                },
                s: {
                  col: 12,
                },
                l: {
                  col: 8,
                },
              }}
              dangerouslySetInnerHTML={{ __html: course?.description }}
            ></GridItem>
          </Grid>
        </GridItem>
        <GridItem
          col="3"
          breakpoints={{
            xs: {
              col: 9,
            },
            s: {
              col: 9,
            },
            l: {
              col: 3,
            },
          }}
        >
          <OneCourseSidebar course={course} status={course?.result} />
        </GridItem>
      </Grid>
      <Grid cols="12">
        <GridItem col="12">
          <FinalTest
            course={course}
            status={course?.result}
            style={{ marginTop: '48px' }}
          />
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};
