import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';

import React from 'react';

export const FooterCallBackForm = () => {
  return (
    <div style={{ marginTop: '32px' }}>
      <TextField
        placeholder="myadress@mail.ru"
        style={{ width: '260px', marginRight: '12px' }}
      />
      <Button label="Подписаться" />
    </div>
  );
};
