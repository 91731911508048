import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import React from 'react';
import styles from './OneServiceInformationBlock.module.scss';
export const OneServiceInformationBlock = ({ service }) => {
  return (
    <Card
      verticalSpace="l"
      horizontalSpace="l"
      className={styles.OneServiceInformationBlock}
      shadow={false}
      border={false}
    >
      <Text as="h3" size="xl" style={{ color: 'rgba(0, 32, 51, 0.6)' }}>
        Услуги, оказываемые за счёт Центра:
      </Text>
      <div
        dangerouslySetInnerHTML={{ __html: service?.acf?.otherInformation }}
      ></div>
    </Card>
  );
};
