import axios from 'axios';
import { ENV } from '../utils/env';
// import Cookies from 'js-cookie';

export const getOneMediaByMediaId = async ({ mediaId }) => {
  try {
    const token = 'Y3BlLWFkbWluOmNwZS1wYXNzd29yZC0yMDIy'; //|| Cookies.get('accessToken');

    let config = {
      method: 'get',
      url: `${ENV.API_URL}/media/${mediaId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
