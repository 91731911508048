import { Card } from '@consta/uikit/Card';
import React, { useEffect, useState } from 'react';
import styles from './MaterialNavigationList.module.scss';
import { MaterialItemSmall } from '../MaterialItem/MaterialItemSmall';
import { useApi } from '../../hooks/useApi';
import { getAllCourseMaterials } from '../../api/MaterialApi';

export const MaterialNavigationList = ({ course }) => {
  const [materials, setMaterials] = useState([]);
  const meterialsApi = useApi(getAllCourseMaterials);
  useEffect(() => {
    if (course?.materials?.length) setMaterials(course?.materials);
  }, [course]);
  return (
    <Card
      shadow={false}
      border={true}
      className={styles.MaterialNavigationList}
    >
      {materials.map((material) => (
        <MaterialItemSmall
          key={material.id}
          material={material}
          course={course}
        />
      ))}
    </Card>
  );
};
