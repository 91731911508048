// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutCard_AboutCard__j-ePw {\n  background: #F5F7FB;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/AboutCard/AboutCard.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;AACJ","sourcesContent":[".AboutCard {\n    background: #F5F7FB;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AboutCard": "AboutCard_AboutCard__j-ePw"
};
export default ___CSS_LOADER_EXPORT___;
