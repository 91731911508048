import React from 'react';
import { Text } from '@consta/uikit/Text';
export const H3 = ({ children, className }) => {
  return (
    <Text
      type="h3"
      size="2xl"
      className={className}
      lineHeight="2xs"
      style={{ margin: 0 }}
    >
      {children}
    </Text>
  );
};
