// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_ForgotPasswordLink__i5XRX {\n  text-decoration: none;\n  color: rgba(0, 57, 92, 0.8);\n  font-size: 12px;\n  display: block;\n}\n.Form_ForgotPasswordLink__i5XRX:hover {\n  text-decoration: underline;\n}\n\n.Form_CustomLabel__h6EGF {\n  font-size: 16px !important;\n  display: block !important;\n  color: rgba(0, 32, 51, 0.6) !important;\n  margin-bottom: 8px !important;\n  margin-top: 16px !important;\n}\n.Form_CustomLabel__h6EGF span {\n  margin-bottom: -5px;\n  cursor: pointer;\n  display: inline-block;\n  margin-right: 5px;\n}\n\n.Form_Success__s2RCu {\n  text-align: center;\n}\n\n@media only screen and (max-width: 480px) {\n  .Form_SignForm__gYyQm {\n    padding: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Default/Form/Form.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,2BAAA;EACA,eAAA;EACA,cAAA;AACJ;AACI;EACI,0BAAA;AACR;;AAEA;EACI,0BAAA;EACA,yBAAA;EACA,sCAAA;EACA,6BAAA;EACA,2BAAA;AACJ;AAAI;EACI,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;AAER;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI;IACI,aAAA;EACN;AACF","sourcesContent":[".ForgotPasswordLink {\n    text-decoration: none;\n    color: rgba(0, 57, 92, 0.8);\n    font-size: 12px;\n    display: block;\n\n    &:hover {\n        text-decoration: underline;\n    }\n}\n.CustomLabel {\n    font-size: 16px !important;\n    display: block !important;\n    color: #00203399 !important;\n    margin-bottom: 8px !important;\n    margin-top: 16px !important;\n    span {\n        margin-bottom: -5px;\n        cursor: pointer;\n        display: inline-block;\n        margin-right: 5px;\n    }\n}\n\n.Success {\n    text-align: center;\n}\n\n@media only screen and (max-width: 480px){\n    .SignForm {\n        padding: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ForgotPasswordLink": "Form_ForgotPasswordLink__i5XRX",
	"CustomLabel": "Form_CustomLabel__h6EGF",
	"Success": "Form_Success__s2RCu",
	"SignForm": "Form_SignForm__gYyQm"
};
export default ___CSS_LOADER_EXPORT___;
