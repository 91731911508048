import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { getCertificate } from '../../api/CertificateApi';
// import { getCourseStatus } from '../../api/CourseApi';
import { useApi } from '../../hooks/useApi';
// import { useSiteChecker } from '../../hooks/useSiteChecker';
import { URLS } from '../../utils/urls.util';
// import { CertificatePDF } from '../CertificatePDF/CertificatePDF';
// import { CertItemSmall } from '../CertItem/CerItemSmall';

// import { FileCert } from '../File/FileCert';
import styles from './TestResult.module.scss';
import { getTestResult } from '../../api/TestApi';
import { BlockContainer } from '../../components/Default/BlockContainer/BlockContainer';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { useCookies } from 'react-cookie';
import { CertItemSmall } from '../../components/CertItem/CerItemSmall';
import { getCertificate } from '../../api/UserApi';
export const TestResult = ({
  title,
  description,
  success,
  successActionText,
  successActionUrl,
  badActionText,
  badActionUrl,
  course,
  test,
  nextMaterial,
}) => {
  const { courseId, materialId, testId, resultId } = useParams();
  const [finalTestLink, setFinalTestLink] = useState(null);
  const [certificate, setCertificate] = useState({});
  const [testResult, setTestResult] = useState({});
  const getTestResultApi = useApi(getTestResult);
  const getCertificateApi = useApi(getCertificate);
  useEffect(() => {
    getTestResultApi
      .sendRequest({ testId })
      .then((result) => setTestResult(result));
    getCertificateApi.sendRequest().then((result) => setCertificate(result));
  }, []);
  const [cookies, setCookie] = useCookies(['jwt']);
  return (
    <MainContainer
      showFooter={true}
      auth={!!cookies.jwt}
      seoTitle={testResult?.resultTitle}
    >
      <BlockContainer
        style={{ paddingTop: '48px', overflow: 'hidden', minHeight: '50vh' }}
      >
        <div className={styles.TestResult__Background}></div>

        <Grid cols="12" gap="3xl">
          <GridItem col="6">
            <Text as="h1" view="h1" size="3xl">
              {testResult?.resultTitle}
            </Text>
            <div
              dangerouslySetInnerHTML={{
                __html: testResult?.resultDescription,
              }}
            ></div>

            <Button
              label={testResult?.nextLinkText || 'Продолжить'}
              view="primary"
              size="l"
              as="a"
              className={styles.TestResult__ActionButton}
              href={testResult?.nextLinkUrl}
            />
          </GridItem>
          <GridItem col="1"></GridItem>
          <GridItem col="4">
            {certificate?.company?.inn && <CertItemSmall cert={certificate} />}
          </GridItem>
        </Grid>
      </BlockContainer>
    </MainContainer>
  );
};
