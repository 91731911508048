import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { getAllCourses } from '../../api/CourseApi';
import { CoursePageGrid } from '../../components/CoursePageGrid/CoursePageGrid';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { MainScreen } from '../../components/Home/MainScreen/MainScreen';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';

export const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const getAllCoursesApi = useApi(getAllCourses);
  useEffect(() => {
    getAllCoursesApi
      .sendRequest({ relations: ['materials', 'service', 'file'] })
      .then(([result, count]) => {
        setCourses(result);
      })
      .catch((error) => console.log(error));
  }, []);
  const breadcrumbs = [
    {
      label: 'Главная',
      href: URLS.HOME,
    },
    {
      label: 'Курсы',
      href: URLS.COURSES_PAGE,
    },
  ];

  return (
    <>
      <RedirectComponent />

      <MainContainer
        seoTitle="Наши курсы"
        showFooter={true}
        auth={true}
        breadcrumbs={breadcrumbs}
      >
        <MainScreen
          title="Наши курсы"
          titleSize="5xl"
          style={{ height: '40vh', paddingTop: '50px', maxHeight: '450px' }}
        />
        <CoursePageGrid courses={courses} />
      </MainContainer>
    </>
  );
};
