import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import React, { useEffect, useState } from 'react';
import Sticky, { RenderPropSticky } from 'react-sticky-el';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { Devider } from '../Default/Devider/Devider';
import styles from './OneCourseSidebar.module.scss';
import { getAllCourseMaterials } from '../../api/MaterialApi';
import { useApi } from '../../hooks/useApi';
import { getAllServices } from '../../api/ServicesApi';
import { CourseItemInSidebar } from '../CourseItem/CourseItemInSidebar';
import { Text } from '@consta/uikit/Text';

import { canStartCourse } from '../../utils/canStartCourse';

export const OneCourseSidebar = ({ status, course }) => {
  const [materials, setMaterials] = useState([]);
  const [service, setService] = useState({});
  const [checkResult, setCheckResult] = useState({});
  useEffect(() => {
    setMaterials(course.materials);
    setService(course.service);
  }, [course]);

  useEffect(() => {
    setCheckResult(canStartCourse(course, status));
  }, [course, status]);

  return (
    <>
      <Card verticalSpace="l" horizontalSpace="l">
        {checkResult?.canView &&
        !checkResult?.nextStartDate &&
        materials?.length ? (
          <Button
            label="Начать курс"
            as="a"
            href={'/education/' + course?.id + '/' + materials[0]?.id}
            iconRight={IconArrowRight}
            width="full"
          />
        ) : (
          <>
            <Text as="h5" style={{ margin: '10px 0' }}>
              Вы пока не можете пройти курс повторно
            </Text>
            <Text as="p">
              Пройти курс повторно можно после: {checkResult?.nextStartDate}
            </Text>
          </>
        )}
      </Card>

      {/* {status?.status === 'finished' && service && (
       <div>
        <Text as="h6" style={{ margin: '10px 0', marginTop: '30px' }}>
          Вам доступна услуга
        </Text>
        <CourseItemInSidebar item={service} slice={50} />
      </div>
      )} */}
    </>
  );
};
