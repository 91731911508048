import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { MainContainer } from '../Default/MainContaner/MainContainer';
import { Button } from '@consta/uikit/Button';
import styles from './TestPreview.module.scss';
import { QuestionsList } from '../QuestionsList/QuestionsList';
import { useNavigate } from 'react-router-dom';

export const TestPreview = ({
  title,
  description,
  actionText,
  actionUrl,
  previewImg,
  hideMenu,
  permissions,
  test,
}) => {
  const [startQeustions, setStartQeustions] = useState(false);
  const [cookies, setCookie] = useCookies(['jwt']);
  const navigate = useNavigate();
  return (
    <MainContainer
      showFooter={true}
      auth={!!cookies.jwt}
      hideMenu={hideMenu}
      permissions={permissions}
      className={styles.TestPreview}
      seoTitle={title}
    >
      <BlockContainer style={{ paddingTop: '48px', overflow: 'hidden' }}>
        {!startQeustions && (
          <>
            <div className={styles.TestPreview__Background}></div>
            <Grid cols="12" gap="3xl">
              <GridItem
                col="7"
                breakpoints={{
                  xs: {
                    col: 9,
                  },
                  s: {
                    col: 9,
                  },
                  l: {
                    col: 7,
                  },
                }}
              >
                <Text as="h1" view="h1" size="3xl">
                  {title}
                </Text>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                <Button
                  label={actionText}
                  view="primary"
                  size="l"
                  className={styles.TestPreview__ActionButton}
                  onClick={() => navigate(actionUrl)}
                />
              </GridItem>
            </Grid>
          </>
        )}
      </BlockContainer>
    </MainContainer>
  );
};
