import React, { useEffect, useState } from 'react';
import styles from './Player.module.scss';

export const YoutubeVideoPlayer = ({ url }) => {
  const [normalUrl, setNormalUrl] = useState('');
  useEffect(() => {
    if (url) {
      const splitUrl = url?.split('/');
      if (splitUrl?.length) {
        setNormalUrl(
          `https://www.youtube.com/embed/${splitUrl[splitUrl?.length - 1]}`,
        );
      }
    }
  }, [url]);
  return (
    <div className={styles.Player}>
      <iframe
        width="100%"
        height="100%"
        src={normalUrl}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; picture-in-picture;"
      ></iframe>
    </div>
  );
};
