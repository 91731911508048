import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOneCourseMaterialById } from '../../api/MaterialApi';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { MaterialGrid } from '../../components/MaterialGrid/MaterialGrid';
import { useApi } from '../../hooks/useApi';
import { sort } from '../../utils/sort';
import { URLS } from '../../utils/urls.util';

export const MaterialPage = () => {
  const [course, setCourse] = useState({});
  const [material, setMaterial] = useState({});
  const materialApi = useApi(getOneCourseMaterialById);
  const { materialId, courseId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [settings, setSettings] = useState();
  useEffect(() => {
    const materialPromise = materialApi.sendRequest({
      materialId,
      relations: [
        'courses',
        'courses.materials',
        'test',
        'courses.finalTest',
        'courses.materials.file',
      ],
      courseId,
    });
    Promise.all([materialPromise]).then(([material]) => {
      material.courses[0].materials =
        sort(material?.courses[0]?.materials || []) || [];
      setCourse(material.courses[0]);
      setMaterial(material);
    });
  }, []);

  useEffect(() => {
    if (course && material?.courses?.length) {
      setBreadcrumbs([
        {
          label: 'Главная',
          href: URLS.HOME,
        },
        {
          label: 'Курсы',
          href: URLS.COURSES_PAGE,
        },
        {
          label: material?.courses[0]?.title,
          href: URLS.COURSES_PAGE + '/' + material?.courses[0]?.id,
        },
        {
          label: material?.title,
          href:
            URLS.COURSES_PAGE +
            '/' +
            material?.courses[0]?.id +
            '/' +
            material?.id,
        },
      ]);
    }
  }, [course, material]);

  return (
    <>
      <RedirectComponent />
      <MainContainer
        seoTitle={material?.title}
        showFooter={true}
        auth={true}
        breadcrumbs={breadcrumbs}
        settingsHandler={(data) => setSettings(data)}
      >
        <MaterialGrid course={course} material={material} settings={settings} />
      </MainContainer>
    </>
  );
};
