import { Text } from '@consta/uikit/Text';
import React from 'react';

export const MaterialDescription = ({ material }) => {
  return (
    <div>
      <Text
        as="h1"
        size="2xl"
        dangerouslySetInnerHTML={{ __html: material?.title }}
      ></Text>
      <div dangerouslySetInnerHTML={{ __html: material?.description }}></div>
    </div>
  );
};
