import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import styles from './MaterialItem.module.scss';
import notFoundImg from '../../assets/images/no-image.png';
import { getOneMediaByMediaId } from '../../api/MediaApi';
import { useApi } from '../../hooks/useApi';
import { Link } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
export const MaterialItemSmall = ({ material, course }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (material?.title?.length > 45) {
      setTitle(material.title.slice(0, 45) + '...');
    } else {
      setTitle(material?.title);
    }
  }, [material]);
  return (
    course && (
      <a href={URLS.COURSES_PAGE + '/' + course?.id + '/' + material?.id}>
        <Card
          className={styles.MaterialItemSmall}
          border={false}
          shadow={false}
          verticalSpace="xs"
          horizontalSpace="s"
        >
          <Grid cols="12" gap="l" xAlign="center">
            <GridItem col="4">
              <div className={styles.MaterialItemSmall__Image}>
                <img alt="" src={material?.imageUrl || notFoundImg} />
              </div>
            </GridItem>
            <GridItem col="8">
              <Text as="p" className={styles.MaterialItemSmall__LessonTitle}>
                {material?.sortPosition}
              </Text>
              <Text
                as="p"
                className={styles.MaterialItemSmall__Title}
                dangerouslySetInnerHTML={{ __html: title }}
              ></Text>
            </GridItem>
          </Grid>
        </Card>
      </a>
    )
  );
};
