import React, { useState } from 'react';
import { RegistrationForm } from '../../components/Default/Form/RegistrationForm';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { useCookies } from 'react-cookie';
import { LoginFormContaner } from '../../components/Login/LoginFormContaner';
import { Navigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
export const RegistrationPage = () => {
  const [success, setSuccess] = useState(false);
  const [cookies, setCookie] = useCookies(['jwt']);
  return (
    <>
      {cookies?.jwt && <Navigate to={URLS.HOME} />}
      <MainContainer seoTitle="Регистрация">
        <LoginFormContaner page="registration" success={success}>
          <RegistrationForm successHandler={(data) => setSuccess(data)} />
        </LoginFormContaner>
      </MainContainer>
    </>
  );
};
