import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCertificate } from '../../api/CertificateApi';
import { getCourseStatus } from '../../api/CourseApi';
import { useApi } from '../../hooks/useApi';
import { useSiteChecker } from '../../hooks/useSiteChecker';
import { URLS } from '../../utils/urls.util';
import { CertificatePDF } from '../CertificatePDF/CertificatePDF';
import { CertItemSmall } from '../CertItem/CerItemSmall';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { FileCert } from '../File/FileCert';
import styles from './TestResult.module.scss';
export const TestResult = ({
  title,
  description,
  success,
  successActionText,
  successActionUrl,
  badActionText,
  badActionUrl,
  course,
  test,
  nextMaterial,
}) => {
  const { courseId, materialId, testId } = useParams();
  const [finalTestLink, setFinalTestLink] = useState(null);
  const { getData, data } = useSiteChecker();
  const [goToEducationPage, setGoToEducationPage] = useState(false);
  const [certificate, setCertificate] = useState({});
  const getAllCoursesResultApi = useApi(getCourseStatus);

  useEffect(() => {
    if (course?.finalTest?.id) {
      setFinalTestLink(
        URLS?.COURSES_PAGE +
          '/' +
          courseId +
          '/' +
          materialId +
          '/' +
          course?.finalTest?.id,
      );
      if (testId === course?.finalTest?.id) {
        setGoToEducationPage(true);
      } else {
        setGoToEducationPage(false);
      }
    }
  }, [course]);
  useEffect(() => {
    getData().then((response) => {
      getAllCoursesResultApi.sendRequest({}).then((result) => {
        setCertificate({
          tests: [
            response.maturityTestCheck || {},
            response.finalTestCheck || {},
          ],
          courses: result?.some((r) => r.id) ? result : [],
          company: result[0]?.companySnapshot,
        });
      });
    });
  }, []);

  const checkNextMaterial = (currentMaterialId) => {
    const currentMaterialIndex = course?.materials?.findIndex(
      (m) => m.id == currentMaterialId,
    );
    const nextMaterialIndex = currentMaterialIndex + 1;

    return nextMaterialIndex >= course?.materials?.length
      ? false
      : course?.materials[nextMaterialIndex] &&
          course?.materials[nextMaterialIndex]?.id;
  };
  return (
    <BlockContainer style={{ paddingTop: '48px', overflow: 'hidden' }}>
      <div className={styles.TestResult__Background}></div>
      <Grid cols="12" gap="3xl">
        <GridItem col="6">
          <Text as="h1" view="h1" size="3xl">
            {title}
          </Text>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>

          {success && successActionText && successActionUrl ? (
            nextMaterial == materialId &&
            course?.finalTest &&
            course?.finalTest?.id != testId ? (
              <Button
                label="Пройти итоговый тест по курсу"
                view="primary"
                size="l"
                as="a"
                className={styles.TestResult__ActionButton}
                href={finalTestLink}
              />
            ) : checkNextMaterial(materialId) ? (
              <Button
                label="Продолжить курс"
                view="primary"
                size="l"
                as="a"
                className={styles.TestResult__ActionButton}
                href={
                  URLS.COURSES_PAGE +
                  '/' +
                  course.id +
                  '/' +
                  checkNextMaterial(materialId)
                }
              />
            ) : (
              <Button
                label={successActionText}
                view="primary"
                size="l"
                as="a"
                className={styles.TestResult__ActionButton}
                href={successActionUrl}
              />
            )
          ) : (
            <Button
              label={badActionText}
              view="primary"
              size="l"
              className={styles.TestResult__ActionButton}
              href={badActionUrl}
              as="a"
            />
          )}
        </GridItem>
        <GridItem col="1"></GridItem>
        <GridItem col="4">
          {!!checkNextMaterial() && success && !!certificate && (
            <CertItemSmall cert={certificate} />
          )}
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};
