// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomePage_HomePage__MainScreen__Button__2hcxq {\n  padding-left: 92px;\n  padding-right: 92px;\n  margin-top: 40px;\n}", "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.scss"],"names":[],"mappings":"AAEQ;EACI,kBAAA;EACA,mBAAA;EACA,gBAAA;AADZ","sourcesContent":[".HomePage{\n    &__MainScreen {\n        &__Button {\n            padding-left: 92px;\n            padding-right: 92px;\n            margin-top: 40px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomePage__MainScreen__Button": "HomePage_HomePage__MainScreen__Button__2hcxq"
};
export default ___CSS_LOADER_EXPORT___;
