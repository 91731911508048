import { Text } from '@consta/uikit/Text';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, useParams } from 'react-router-dom';
import { getOneCourseById } from '../../api/CourseApi';
import { getOneTestById } from '../../api/TestApi';
import { createUser, getCusomerPermissions, getMe } from '../../api/UserApi';
import { BlockContainer } from '../../components/Default/BlockContainer/BlockContainer';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { ProfileDataCard } from '../../components/ProfileDataCard/ProfileDataCard';
import { QuestionsList } from '../../components/QuestionsList/QuestionsList';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { AlertsList } from '../../components/Default/AlertsList/AlertsList';

export const ProfilePage = () => {
  const [cookies, setCookie] = useCookies(['jwt']);
  const [user, setUser] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const getMeApi = useApi(getMe);
  const updateUserApi = useApi(createUser);
  const permissionsApi = useApi(getCusomerPermissions);
  const breadcrumbs = [
    {
      label: 'Главная',
      href: URLS.HOME,
    },
    {
      label: 'Профиль',
      href: URLS.PROFILE,
    },
  ];

  useEffect(() => {
    getMeApi.sendRequest().then((result) => setUser(result));
    permissionsApi.sendRequest().then((result) => setPermissions(result));
  }, []);

  const updateHandler = async (data) => {
    const result = await updateUserApi.sendRequest(data);

    if (result.id) {
      delete result.password;
      setUser(result);
    }
    setAlerts([{ text: 'Успешно сохранено', type: 'success' }]);
  };
  const [comaprePassError, setComparePassError] = useState(false);
  useEffect(() => {
    if (user) {
      if (user?.newPasswordAgain && user?.password) {
        setComparePassError(user.newPasswordAgain != user.password);
      }
    }
  }, [user]);
  return (
    <>
      <AlertsList alerts={alerts} />
      <RedirectComponent />
      <MainContainer
        seoTitle="Профиль"
        permissions={permissions}
        showFooter={true}
        auth={!!cookies.jwt}
        breadcrumbs={breadcrumbs}
      >
        <BlockContainer style={{ paddingTop: '48px', overflow: 'hidden' }}>
          <Text as="h1" size="3xl">
            Профиль
          </Text>
          <ProfileDataCard
            user={user}
            comaprePassError={comaprePassError}
            changeState={(data) => setUser(data)}
            updateHandler={(data) => updateHandler(data)}
          />
        </BlockContainer>
      </MainContainer>
    </>
  );
};
