import { Grid, GridItem } from '@consta/uikit/Grid';
import React from 'react';
import { CourseItemInCoursesPage } from '../CourseItem/CourseItemInCoursesPage';
import { CoursesPageSidebar } from '../CoursePageSidebar/CoursesPageSidebar';
import { BlockContainer } from '../Default/BlockContainer/BlockContainer';
import { Pagination } from '@consta/uikit/Pagination';

export const CoursePageGrid = ({ courses, permissions }) => {
  return (
    <BlockContainer style={{ paddingTop: '48px' }}>
      <Grid cols="12" gap="3xl">
        <GridItem
          col="3"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 4,
            },
            l: {
              col: 3,
            },
          }}
        >
          <CoursesPageSidebar />
        </GridItem>
        <GridItem
          col="9"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 8,
            },
            l: {
              col: 9,
            },
          }}
        >
          <Grid cols="12" gap="xl">
            {courses?.length
              ? courses?.map((item) => (
                  <GridItem
                    key={item?.id}
                    col="4"
                    breakpoints={{
                      xs: {
                        col: 12,
                      },
                      s: {
                        col: 6,
                      },
                      l: {
                        col: 4,
                      },
                    }}
                  >
                    <CourseItemInCoursesPage item={item} />
                  </GridItem>
                ))
              : 'Материалы пока не добавлены'}
          </Grid>
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};
