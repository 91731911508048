import React, { useState } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Form.module.scss';
import { Button } from '@consta/uikit/Button';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { useCookies } from 'react-cookie';
import { add } from 'date-fns';
import { useApi } from '../../../hooks/useApi';
import { Auth } from '../../../api/AuthApi';
import { getCheckUserMaturityTest } from '../../../api/CheckerApi';
import { getAllSettings } from '../../../api/SettingsApi';
import { URLS } from '../../../utils/urls.util';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../store/alert.store';

export const LoginForm = () => {
  const [authData, setAuthData] = useState({});

  const authApi = useApi(Auth);
  const checkerApi = useApi(getCheckUserMaturityTest);
  const settingsApi = useApi(getAllSettings);
  const [cookies, setCookie] = useCookies(['jwt']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthHandler = async () => {
    try {
      const result = await authApi.sendRequest({
        email: authData.email,
        password: authData.password,
      });
      if (typeof result == 'string') {
        setCookie('jwt', result, {
          expires: add(new Date(), { seconds: 86400 }),
        });
      }
    } catch (error) {
      dispatch(setAlert({ text: error?.response?.data?.message, status: 409 }));
    }
  };

  return (
    <div style={{ marginTop: '40px' }} className={styles.SignForm}>
      <TextField
        label="Введите ваш e-mail"
        required
        placeholder="myadress@gmail.com"
        width="full"
        value={authData?.email}
        onChange={({ value }) => setAuthData({ ...authData, email: value })}
      />
      <TextField
        label="Введите пароль"
        required
        type="password"
        placeholder="********"
        width="full"
        style={{ marginTop: '16px' }}
        value={authData?.password}
        onChange={({ value }) => setAuthData({ ...authData, password: value })}
      />
      <Link
        to={URLS.FORGOT_PASSWORD}
        style={{ marginTop: '10px' }}
        className={styles.ForgotPasswordLink}
      >
        Забыли пароль
      </Link>

      <Button
        label="Продолжить"
        width="full"
        view="primary"
        style={{ marginTop: '32px' }}
        onClick={AuthHandler}
        loading={authApi.loading}
      />
      {/* <Button
        label="Войти через Гос. услуги"
        width="full"
        view="secondary"
        style={{ marginTop: '12px' }}
      /> */}
    </div>
  );
};
