// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlockContainer_BlockContainer__Beju9 {\n  max-width: 1350px;\n  margin: auto;\n  display: block;\n  padding-bottom: 45px;\n}\n\n@media only screen and (max-width: 768px) {\n  .BlockContainer_BlockContainer__Beju9 {\n    max-width: 100%;\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Default/BlockContainer/BlockContainer.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,YAAA;EACA,cAAA;EACA,oBAAA;AACJ;;AAEA;EACI;IACI,eAAA;IACA,kBAAA;IACA,mBAAA;EACN;AACF","sourcesContent":[".BlockContainer {\n    max-width: 1350px;\n    margin: auto;\n    display: block;\n    padding-bottom: 45px;\n}\n\n@media only screen and (max-width: 768px) {\n    .BlockContainer {\n        max-width: 100%;\n        padding-left: 20px;\n        padding-right: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BlockContainer": "BlockContainer_BlockContainer__Beju9"
};
export default ___CSS_LOADER_EXPORT___;
