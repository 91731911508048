// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaterialGrid_MaterialGrid__TestCard__VyLX\\+ {\n  background: rgba(0, 32, 51, 0.0784313725);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media only screen and (max-width: 480px) {\n  .MaterialGrid_MaterialGrid__TestCard__VyLX\\+ {\n    width: 72.5%;\n    margin-top: 40px;\n  }\n}\n@media only screen and (max-width: 768px) {\n  .MaterialGrid_MaterialGrid__TestCard__VyLX\\+ {\n    margin-top: 40px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/MaterialGrid/MaterialGrid.module.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI;IACI,YAAA;IACA,gBAAA;EACN;AACF;AAEC;EACG;IACI,gBAAA;EAAN;AACF","sourcesContent":[".MaterialGrid__TestCard {\n    background: #00203314;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n@media only screen and (max-width: 480px) {\n    .MaterialGrid__TestCard {\n        width: 72.5%;\n        margin-top: 40px;\n    }\n }\n\n @media only screen and (max-width: 768px) {\n    .MaterialGrid__TestCard {\n        margin-top: 40px;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MaterialGrid__TestCard": "MaterialGrid_MaterialGrid__TestCard__VyLX+"
};
export default ___CSS_LOADER_EXPORT___;
