// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OneCourseGrid_OneCourseGrid__Status__0XUl9 {\n  padding: 5px 12px;\n  background: #0078d1;\n  margin-bottom: -30px;\n  color: #fff;\n  border-radius: 3px;\n  display: inline-block;\n}\n.OneCourseGrid_OneCourseGrid__ResultDate__A20Xe {\n  padding: 5px 12px;\n  background: #eee;\n  margin-bottom: -30px;\n  color: #222;\n  border-radius: 3px;\n  margin-left: 15px;\n  display: inline-block;\n}", "",{"version":3,"sources":["webpack://./src/components/OneCourseGrid/OneCourseGrid.module.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,mBAAA;EACA,oBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AAAR;AAEI;EACI,iBAAA;EACA,gBAAA;EACA,oBAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;AAAR","sourcesContent":[".OneCourseGrid {\n    &__Status {\n        padding: 5px 12px;\n        background: #0078d1;\n        margin-bottom: -30px;\n        color: #fff;\n        border-radius: 3px;\n        display: inline-block;\n    }\n    &__ResultDate {\n        padding: 5px 12px;\n        background: #eee;\n        margin-bottom: -30px;\n        color: #222;\n        border-radius: 3px;\n        margin-left: 15px;\n        display: inline-block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OneCourseGrid__Status": "OneCourseGrid_OneCourseGrid__Status__0XUl9",
	"OneCourseGrid__ResultDate": "OneCourseGrid_OneCourseGrid__ResultDate__A20Xe"
};
export default ___CSS_LOADER_EXPORT___;
