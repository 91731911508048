// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OneServiceInformationBlock_OneServiceInformationBlock__veuli {\n  padding: 16px;\n  background: #F5F7FB;\n  color: #002033;\n  border-radius: 8px !important;\n}\n.OneServiceInformationBlock_OneServiceInformationBlock__veuli h3 {\n  color: rgba(0, 32, 51, 0.6);\n}\n.OneServiceInformationBlock_OneServiceInformationBlock__veuli ul {\n  padding-left: 21px;\n}", "",{"version":3,"sources":["webpack://./src/components/OneServiceInformationBlock/OneServiceInformationBlock.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,6BAAA;AACJ;AAAI;EACI,2BAAA;AAER;AAAI;EACI,kBAAA;AAER","sourcesContent":[".OneServiceInformationBlock {\n    padding: 16px;\n    background: #F5F7FB;\n    color: #002033;\n    border-radius: 8px !important; \n    h3 {\n        color: #00203399\n    }\n    ul {\n        padding-left: 21px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OneServiceInformationBlock": "OneServiceInformationBlock_OneServiceInformationBlock__veuli"
};
export default ___CSS_LOADER_EXPORT___;
