// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverBlock_PopoverBlcok__0xhO4 {\n  background: #fff;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);\n  border-radius: 9px;\n  padding: 15px;\n  max-width: 300px;\n}", "",{"version":3,"sources":["webpack://./src/components/Default/PopoverBlock/PopoverBlock.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,uCAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;AACJ","sourcesContent":[".PopoverBlcok {\n    background: #fff;\n    box-shadow: 0 0 20px rgba(0,0,0,0.2);\n    border-radius: 9px;\n    padding: 15px;\n    max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PopoverBlcok": "PopoverBlock_PopoverBlcok__0xhO4"
};
export default ___CSS_LOADER_EXPORT___;
