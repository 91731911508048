import { Text } from '@consta/uikit/Text';
import { Popover } from '@consta/uikit/__internal__/src/components/Popover/Popover';
import React from 'react';
import styles from './PopoverBlock.module.scss';
export const PopoverBlock = ({
  isPopoverVisible,
  anchorRef,
  onClickOutside,
  text,
}) => {
  return (
    <Popover
      direction="upCenter"
      spareDirection="downStartLeft"
      offset="2xs"
      arrowOffset={0}
      isInteractive={true}
      anchorRef={anchorRef}
      equalAnchorWidth={false}
      className={styles.PopoverBlcok}
    >
      <div>
        <Text size="xs">{text}</Text>
      </div>
    </Popover>
  );
};
