import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import React, { useState } from 'react';
import { CallbackForm } from '../CallbackForm/CallbackForm';
import { ResetPasswordForm } from '../ResetPasswordForm/ResetPasswordForm';
import { AlertsList } from '../Default/AlertsList/AlertsList';

export const ProfileDataCard = ({
  user,
  changeState,
  updateHandler,
  comaprePassError,
}) => {
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [alerts, setAlerts] = React.useState([]);
  return (
    <>
      <Grid cols="12" gap="3xl">
        <GridItem
          col={6}
          breakpoints={{
            xs: {
              col: 9,
            },
            s: {
              col: 9,
            },
            l: {
              col: 6,
            },
          }}
        >
          {openSetPassword ? (
            <ResetPasswordForm
              user={user}
              changeState={(data) => changeState(data)}
            />
          ) : (
            <>
              <Text
                as="h4"
                size="l"
                style={{ paddingBottom: 0, marginBottom: 0 }}
              >
                Личные данные
              </Text>

              <Text as="h6" size="s" style={{ paddingTop: 0, marginTop: 0 }}>
                ИНН организации: {user?.company?.inn}
              </Text>
              <TextField
                placeholder="Имя"
                label="Ваше имя:"
                width="full"
                value={user?.firstName}
                style={{ marginBottom: '10px' }}
                onChange={({ value }) =>
                  changeState({ ...user, firstName: value })
                }
              />
              <TextField
                placeholder="Фамилия"
                label="Ваша фамилия:"
                width="full"
                value={user?.lastName}
                style={{ marginBottom: '10px' }}
                onChange={({ value }) =>
                  changeState({ ...user, lastName: value })
                }
              />
              <TextField
                placeholder="Номер телефона:"
                label="Номер телефона:"
                width="full"
                value={user?.phone}
                style={{ marginBottom: '10px' }}
                onChange={({ value }) => changeState({ ...user, phone: value })}
              />
              <TextField
                placeholder="Email:"
                label="Email:"
                width="full"
                value={user?.email}
                style={{ marginBottom: '10px' }}
                onChange={({ value }) => changeState({ ...user, email: value })}
              />
            </>
          )}

          <Button
            label="Сохранить"
            view="primary"
            disabled={
              comaprePassError ||
              (openSetPassword &&
                (!user.password || !user.oldPassword || !user.newPasswordAgain))
            }
            style={{ marginTop: '20px', marginRight: '10px' }}
            onClick={() => updateHandler(user)}
          />
          {openSetPassword ? (
            <Button
              label="Отменить"
              view="secondary"
              style={{ marginTop: '20px' }}
              onClick={() => {
                const newUser = user;
                delete newUser.password;
                changeState({ ...newUser });
                setOpenSetPassword(false);
              }}
            />
          ) : (
            <Button
              label="Сменить пароль"
              view="secondary"
              style={{ marginTop: '20px' }}
              onClick={() => setOpenSetPassword(true)}
            />
          )}
        </GridItem>
        <GridItem
          col="6"
          breakpoints={{
            xs: {
              col: 9,
            },
            s: {
              col: 9,
            },
            l: {
              col: 6,
            },
          }}
        >
          <Card verticalSpace="2xl" horizontalSpace="2xl">
            <Text as="h6" size="s" style={{ paddingTop: 0, marginTop: 0 }}>
              Обратная связь
            </Text>
            <AlertsList alerts={alerts} />
            <CallbackForm
              openAlertHandler={(data) =>
                setAlerts([
                  {
                    text: 'Сообщение успещно отправлено! Мы свяжимся с вами в ближайшее время',
                    type: 'success',
                  },
                ])
              }
            />
          </Card>
        </GridItem>
      </Grid>
    </>
  );
};
