// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OneCourseSidebar_OneCourseSidebar__Navigation__W3oK8 {\n  padding-left: 0;\n}\n.OneCourseSidebar_OneCourseSidebar__Navigation__W3oK8 li {\n  list-style: none;\n  padding: 10px 20px;\n  margin-left: -20px;\n  cursor: pointer;\n  border-left: solid 2px #fff;\n}\n.OneCourseSidebar_OneCourseSidebar__Navigation__W3oK8 li:hover {\n  color: #818181;\n  border-left: solid 2px #818181;\n}\n.OneCourseSidebar_OneCourseSidebar__Navigation_Active__yqqY0 {\n  color: #0078D2 !important;\n  border-left: solid 2px #0078D2 !important;\n}", "",{"version":3,"sources":["webpack://./src/components/OneCourseSidebar/OneCourseSidebar.module.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AACQ;EACI,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;AACZ;AAAY;EACI,cAAA;EACA,8BAAA;AAEhB;AACQ;EACI,yBAAA;EACA,yCAAA;AACZ","sourcesContent":[".OneCourseSidebar{\n    &__Navigation {\n        padding-left: 0;\n        li{\n            list-style: none;\n            padding: 10px 20px;\n            margin-left: -20px;\n            cursor: pointer;\n            border-left: solid 2px #fff;\n            &:hover {\n                color: #818181;\n                border-left: solid 2px #818181;\n            }\n        }\n        &_Active {\n            color: #0078D2 !important;\n            border-left: solid 2px #0078D2 !important;\n        }\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OneCourseSidebar__Navigation": "OneCourseSidebar_OneCourseSidebar__Navigation__W3oK8",
	"OneCourseSidebar__Navigation_Active": "OneCourseSidebar_OneCourseSidebar__Navigation_Active__yqqY0"
};
export default ___CSS_LOADER_EXPORT___;
