import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import styles from './FinalTest.module.scss';
import React, { useEffect, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { URLS } from '../../utils/urls.util';
import { canStartCourse } from '../../utils/canStartCourse';

export const FinalTest = ({ course, status, ...props }) => {
  const [checkResult, setCheckResult] = useState(null);
  useEffect(() => {
    setCheckResult(canStartCourse(course, status));
  }, [course, status]);
  return (
    <Card
      verticalSpace="4xl"
      horizontalSpace="6xl"
      className={styles.FinalTest}
      shadow={false}
      border={false}
      {...props}
    >
      <Grid cols="12" yAlign="center" xAlign="right">
        <GridItem
          col="7"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 12,
            },
            l: {
              col: 7,
            },
          }}
        >
          <Text as="h4" size="3xl" style={{ marginTop: 0 }}>
            Итоговое тестирование
          </Text>
          {course?.viewFinalTest === 'inEnd' ? (
            <Text size="xl">
              Станет доступным для прохождения после того как вы выполните все
              задания текущего курса
            </Text>
          ) : (
            <Text size="xl">
              Вы можете пройти итоговый на любом этапе прохождения курса. После
              прохождения итогового тестирования вы получите сертификат.
            </Text>
          )}
        </GridItem>
        <GridItem
          col="5"
          breakpoints={{
            xs: {
              col: 12,
            },
            s: {
              col: 12,
            },
            l: {
              col: 5,
            },
          }}
        >
          {checkResult?.canView ? (
            course?.viewFinalTest === 'inEnd' ? (
              <Button
                label="Начать курс"
                width="full"
                className={styles.FinalTest__CallBack}
                iconRight={IconArrowRight}
                as="a"
                href={
                  URLS.COURSES_PAGE +
                  '/' +
                  course?.id +
                  '/' +
                  course?.materials[0]?.id
                }
              />
            ) : (
              <>
                {course?.materials?.length && (
                  <Button
                    label="Пройти тест"
                    width="full"
                    className={styles.FinalTest__CallBack}
                    iconRight={IconArrowRight}
                    as="a"
                    href={`${URLS.COURSES_PAGE}/${course.id}/${
                      course?.materials[course?.materials?.length - 1]?.id
                    }/${course?.finalTest?.id}`}
                  />
                )}
              </>
            )
          ) : (
            <>
              <Text as="h5" style={{ margin: '10px 0' }}>
                Вы пока не можете пройти курс повторно
              </Text>
              <Text as="p">
                Пройти курс повторно можно после: {checkResult?.nextStartDate}
              </Text>
            </>
          )}
        </GridItem>
      </Grid>
    </Card>
  );
};
