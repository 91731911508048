import { Button } from '@consta/uikit/Button';
import { File } from '@consta/uikit/File';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import { IconDownload } from '@consta/uikit/IconDownload';
import styles from './File.module.scss';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

export const FileCert = ({ small, loading, cert, ...props }) => {
  return (
    <Grid cols={12} className={styles.File} {...props}>
      <GridItem col={2}>
        <File extension="doc" loading={loading} />
      </GridItem>
      <GridItem col={8}>
        <Text
          as="p"
          type="p"
          className={styles.File__Title}
          style={small && { fontSize: '12px' }}
        >
          {'Сертификат компании ИНН - ' + cert?.company?.inn}
        </Text>
        <Text as="p" type="p" className={styles.File__SubTitle}>
          {format(new Date(), 'dd.MM.yyyy')}
        </Text>
      </GridItem>
      <GridItem col={2}>
        <Button
          onlyIcon={true}
          iconLeft={IconDownload}
          view="ghost"
          size="s"
          style={{ marginLeft: 'auto', display: 'flex' }}
        />
      </GridItem>
    </Grid>
  );
};
