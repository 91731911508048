import axios from 'axios';
import { ENV } from '../utils/env';

export const Auth = async ({ email, password }) => {
  try {
    let config = {
      method: 'post',
      url: `${ENV.BACKEND_URL}/auth/token`,
      data: { email, password },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
