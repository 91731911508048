import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { getAllServices } from '../../api/ServicesApi';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { ServicesGrid } from '../../components/ServicesGrid/ServicesGrid';
import { ServicesMainScreen } from '../../components/ServicesMainScreen/ServicesMainScreen';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { getAllSettings } from '../../api/SettingsApi';
import { getCusomerPermissions } from '../../api/UserApi';

export const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const getAllServicesApi = useApi(getAllServices);
  const [cookies, setCookie] = useCookies(['jwt']);
  const settingsApi = useApi(getAllSettings);
  const [settings, setSettings] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const permissionsApi = useApi(getCusomerPermissions);

  const breadcrumbs = [
    {
      label: 'Главная',
      href: URLS.HOME,
    },
    {
      label: 'Услуги',
      href: URLS.SERVICES,
    },
  ];
  useEffect(() => {
    getAllServicesApi
      .sendRequest({ offset: 0, limit: 999, relations: ['file', 'course'] })
      .then(([result, count]) => {
        setServices(result);
      });
    settingsApi.sendRequest().then((result) => {
      setSettings(result);
    });
    permissionsApi.sendRequest().then((result) => setPermissions(result));
  }, []);

  return (
    <>
      <RedirectComponent />
      <MainContainer
        seoTitle="Услуги"
        showFooter={true}
        auth={true}
        breadcrumbs={breadcrumbs}
        permissions={permissions}
      >
        <ServicesMainScreen settings={settings} />
        <ServicesGrid services={services} />
      </MainContainer>
    </>
  );
};
