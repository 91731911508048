import { TextField } from '@consta/uikit/TextField';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../../../utils/urls.util';
import styles from './Form.module.scss';
import { useApi } from '../../../hooks/useApi';
import { forgotPassword } from '../../../api/UserApi';
import { AlertsList } from '../AlertsList/AlertsList';

export const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const forgotPasswordApi = useApi(forgotPassword);
  const [alerts, setAlerts] = useState([]);
  const forgotPasswordHandler = async () => {
    const result = await forgotPasswordApi.sendRequest({
      email,
    });
    setAlerts([
      {
        text: 'На указанную почту было отправлено письмо для восстановления пароля',
        type: 'success',
      },
    ]);
    setEmail('');
  };
  return (
    <>
      <AlertsList alerts={alerts} />
      <TextField
        placeholder="Ваш email"
        label="Ваш email"
        width="full"
        type="email"
        value={email}
        style={{ marginBottom: '10px', marginTop: '30px' }}
        onChange={({ value }) => {
          setEmail(value);
        }}
      />
      <Stack direction="row" spacing={2} sx={{ my: 2 }}>
        <Link to={URLS.LOGIN} className={styles.ForgotPasswordLink}>
          Вход
        </Link>
        <Link to={URLS.REGISTRATION} className={styles.ForgotPasswordLink}>
          Регистрация
        </Link>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        disabled={!email}
        sx={{ mt: 2 }}
        onClick={forgotPasswordHandler}
      >
        Восстановить пароль
      </Button>
    </>
  );
};
