import { Card } from '@consta/uikit/Card';
import React, { useEffect, useState } from 'react';
import { ProgressLine } from '@consta/uikit/ProgressLine';
import styles from './CourseItem.module.scss';
import notFoundImg from '../../assets/images/no-image.png';
import { Text } from '@consta/uikit/Text';
import { Link } from 'react-router-dom';
import { FileCert } from '../File/FileCert';
import {
  getMaterialsResultCount,
  getMaterialsStatus,
} from '../../api/MaterialApi';
import { getCourseStatus } from '../../api/CourseApi';
import { useApi } from '../../hooks/useApi';
import { URLS } from '../../utils/urls.util';
import { Button } from '@consta/uikit/Button';

export const CourseItemOnHome = ({ course }) => {
  const [title, setTitle] = useState('');

  const [progressPercent, setProgressPercent] = useState(0);

  const materilResultCountApi = useApi(getMaterialsStatus);
  const courseStatusApi = useApi(getCourseStatus);

  useEffect(() => {
    if (course?.title?.length > 27) {
      setTitle(course?.title?.slice(0, 27) + '...');
    } else {
      setTitle(course?.title);
    }
  }, [course]);

  return (
    <Card
      border={true}
      shadow={false}
      horizontalSpace="m"
      verticalSpace="m"
      className={styles.CourseItem}
    >
      <div className={styles.CourseItem__ImageBlock}>
        <Link to={URLS.COURSES_PAGE + '/' + course.id}>
          <img src={course?.imageUrl || notFoundImg} />
        </Link>
      </div>
      <Text className={styles.CourseItem__Title}>
        {progressPercent === 100 ? (
          'Тендорная поддержка'
        ) : (
          <Link to={URLS.COURSES_PAGE + '/' + course.id}>{title}</Link>
        )}
        | <span>{course?.progress || 0}%</span>
      </Text>
      <ProgressLine
        value={course?.progress || 0}
        style={{ marginTop: '25px' }}
      />
      <Button
        label="Продолжить просмотр"
        as="a"
        href={URLS.COURSES_PAGE + '/' + course.id}
        style={{ marginTop: '20px' }}
      />
    </Card>
  );
};
