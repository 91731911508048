// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CoursesBlock_CoursesBlock__j691d {\n  margin: auto;\n  margin-top: -130px;\n  max-width: 1350px;\n  display: block;\n  padding-bottom: 45px;\n}\n.CoursesBlock_CoursesBlock__About__pHegp {\n  background: #F5F7FB;\n  height: 100%;\n}\n\n@media only screen and (max-width: 768px) {\n  .CoursesBlock_CoursesBlock__j691d {\n    margin-top: 40px;\n    padding: 0px 20px 40px 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/CoursesBlock/CoursesBlock.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;AACJ;AAAI;EACI,mBAAA;EACA,YAAA;AAER;;AAEA;EACI;IACI,gBAAA;IACA,2BAAA;EACN;AACF","sourcesContent":[".CoursesBlock {\n    margin: auto;\n    margin-top: -130px;\n    max-width: 1350px;\n    display: block;\n    padding-bottom: 45px;\n    &__About {\n        background: #F5F7FB;\n        height: 100%;\n    }\n}\n\n@media only screen and (max-width: 768px){\n    .CoursesBlock {\n        margin-top: 40px;\n        padding: 0px 20px 40px 20px;\n    }\n}\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CoursesBlock": "CoursesBlock_CoursesBlock__j691d",
	"CoursesBlock__About": "CoursesBlock_CoursesBlock__About__pHegp"
};
export default ___CSS_LOADER_EXPORT___;
