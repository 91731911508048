// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".File_File__bM2XV {\n  margin-top: 45px;\n}\n.File_File__Title__7ZWCZ {\n  font-size: 14px;\n}\n.File_File__SubTitle__VsAWD {\n  font-size: 12px;\n  color: rgba(0, 32, 51, 0.3019607843);\n}", "",{"version":3,"sources":["webpack://./src/components/File/File.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,eAAA;AAER;AAAI;EACI,eAAA;EACA,oCAAA;AAER","sourcesContent":[".File {\n    margin-top: 45px;\n    &__Title {\n        font-size: 14px;\n    }\n    &__SubTitle {\n        font-size: 12px;\n        color: #0020334D;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"File": "File_File__bM2XV",
	"File__Title": "File_File__Title__7ZWCZ",
	"File__SubTitle": "File_File__SubTitle__VsAWD"
};
export default ___CSS_LOADER_EXPORT___;
