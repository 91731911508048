import axios from 'axios';
import { ENV } from '../utils/env';
import Cookies from 'js-cookie';

export const getAllServices = async ({ limit, offset, relations }) => {
  try {
    const token = Cookies.get('accessToken');

    let config = {
      method: 'get',
      url: `${ENV.BACKEND_URL}/service/get`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        limit: 25,
        offset: 0,
        relations: relations || [],
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getOneServiceById = async ({ serviceId, relations }) => {
  try {
    const token = Cookies.get('accessToken');

    let config = {
      method: 'get',
      url: `${ENV.BACKEND_URL}/service/get/${serviceId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        relations: relations || [],
      },
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const checkAllow = async (data) => {
  try {
    const token = Cookies.get('jwt');

    let config = {
      method: 'post',
      url: `${ENV.BACKEND_URL}/service/check-allow`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
