import { Button } from '@consta/uikit/Button';
import React, { useEffect, useState } from 'react';
import { CoursesBlock } from '../../components/CoursesBlock/CoursesBlock';
import { MainContainer } from '../../components/Default/MainContaner/MainContainer';
import { MainScreen } from '../../components/Home/MainScreen/MainScreen';
import { IconForward } from '@consta/uikit/IconForward';
import styles from './HomePage.module.scss';
import { useCookies } from 'react-cookie';
import { URLS } from '../../utils/urls.util';
import { useApi } from '../../hooks/useApi';
import { getStartedCourses } from '../../api/CourseApi';
import { RedirectComponent } from '../../components/Default/RedirectComponent/RedirectComponent';
import { HomeCoursesGrid } from '../../components/HomeCoursesGrid/HomeCoursesGrid';
import { getAllServices } from '../../api/ServicesApi';
import { getAllSettings } from '../../api/SettingsApi';
import { getCusomerPermissions } from '../../api/UserApi';

export const HomePage = () => {
  const [cookies, setCookie] = useCookies(['jwt']);
  const [courses, setCourses] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const getStartedCoursesApi = useApi(getStartedCourses);
  const [services, setServices] = useState([]);
  const getAllServicesApi = useApi(getAllServices);
  const settingsApi = useApi(getAllSettings);
  const [settings, setSettings] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const permissionsApi = useApi(getCusomerPermissions);

  useEffect(() => {
    getStartedCoursesApi
      .sendRequest({
        limit: 4,
        offset: 0,
      })
      .then(([result, count]) => setCourses(result));
    getAllServicesApi
      .sendRequest({ offset: 0, limit: 6, relations: ['file', 'course'] })
      .then(([result, count]) => {
        setServices(result);
      });
    settingsApi.sendRequest().then((result) => {
      setSettings(result);
    });

    permissionsApi.sendRequest().then((result) => setPermissions(result));
  }, []);

  return (
    <>
      <RedirectComponent />

      <MainContainer
        seoTitle="Главная"
        showFooter={true}
        auth={true}
        permissions={permissions}
      >
        <MainScreen
          title={
            courses?.length
              ? settings?.find((s) => s.key == 'mainH1Started')?.value
              : settings?.find((s) => s.key == 'mainH1Begin')?.value
          }
          description={
            courses?.length
              ? settings?.find((s) => s.key == 'mainTextStarted')?.value
              : settings?.find((s) => s.key == 'mainTextBegin')?.value
          }
          actionButton={
            courses?.length ? (
              <Button
                size="l"
                view="primary"
                label="Продолжить обучение"
                as="a"
                href={
                  URLS.COURSES_PAGE + '/' + courses[courses?.length - 1]?.id
                }
                className={styles.HomePage__MainScreen__Button}
                iconRight={IconForward}
              />
            ) : (
              <Button
                size="l"
                view="primary"
                label="Выбрать курс"
                as="a"
                href={URLS.COURSES_PAGE}
                className={styles.HomePage__MainScreen__Button}
                iconRight={IconForward}
              />
            )
          }
        />

        {!!courses?.length && (
          <CoursesBlock courses={courses} settings={settings} />
        )}
        {!!services?.length && <HomeCoursesGrid services={services} />}
      </MainContainer>
    </>
  );
};
