export const URLS = {
  HOME: '/',
  REGISTRATION: '/registration',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:hash',
  COURSES_PAGE: '/education',
  ONE_COURSE_PAGE: '/education/:courseId',
  ONE_COURSE_ONE_MATERIAL_PAGE: '/education/:courseId/:materialId',
  ONE_COURSE_ONE_MATERIAL_TEST_PAGE: '/education/:courseId/:materialId/:testId',
  ONE_COURSE_ONE_MATERIAL_TEST_QUESTION_PAGE:
    '/education/:courseId/:materialId/:testId/:questionId',
  TEST_RESULT: '/test-result/:courseId/:materialId/:testId/result/:resultId',
  SERVICES: '/services',
  ONE_SERVICE_PAGE: '/services/:serviceId',
  SINGLE_TEST: '/single-test/:testId',
  SINGLE_TEST_QEUSTION: '/single-test/:testId/:questionId',
  SINGLE_TEST_RESULT: '/single-test/:testId/result/:resultId',
  PROFILE: '/profile',
  DOCS: '/docs',
  NOT_FOUND: '/404',
};
