import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import React from 'react';
import styles from './MainScreen.module.scss';
import BgImg from '../../../assets/images/main-bg.png';

export const MainScreen = ({
  title,
  description,
  actionButton,
  titleSize = '3xl',
  ...props
}) => {
  return (
    <Card className={styles.MainScreen} shadow={false} form="square" {...props}>
      <div className={styles.MainScreen__Bg}>
        <div className={styles.MainScreen__BgColor}></div>
        <img src={BgImg} />
      </div>
      <Card className={styles.MainScreen__TitleBlock} shadow={false}>
        {title && (
          <Text as="h1" className={styles.MainScreen__H1}>
            {title}
          </Text>
        )}
        {description && <Text as="p">{description}</Text>}
        {actionButton && actionButton}
      </Card>
    </Card>
  );
};
