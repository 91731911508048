import { useCallback, useState } from 'react';
import { getAllSettings } from '../api/SettingsApi';
import { getTestResult, getTestStatus } from '../api/TestApi';
import { useApi } from './useApi';
import { getCusomerPermissions } from '../api/UserApi';

export const useSiteChecker = () => {
  const [data, setData] = useState({});

  const getPermissions = useApi(getCusomerPermissions);
  const getSettingsApi = useApi(getAllSettings);

  const getData = async () => {
    const settings = await getSettingsApi.sendRequest();
    const { maturityTestPermission, finalTestPerimission } =
      await getPermissions.sendRequest();

    const finalData = {
      settings,
      maturityTestId: settings?.find((s) => (s.key = 'maturityTestId'))?.value,
      finalTestId: settings?.find((s) => (s.key = 'finalTest'))?.value,
      maturityTestCheck: maturityTestPermission,
      finalTestCheck: finalTestPerimission,
    };

    setData(finalData);
    return finalData;
  };

  return { data, getData };
};
