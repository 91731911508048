import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

import { useSiteChecker } from '../../../hooks/useSiteChecker';
import { URLS } from '../../../utils/urls.util';

export const RedirectComponent = ({ currentTestChecker }) => {
  const [cookies, setCookie] = useCookies(['jwt']);
  const [rendered, setRendered] = useState(false);
  const { getData, data } = useSiteChecker();
  const [settings, setSettings] = useState({});
  const [maturityTestId, setMaturityTestId] = useState();
  const [maturityTestCheck, setMaturityTestCheck] = useState(false);

  useEffect(() => {
    if (!rendered && cookies?.jwt) {
      getData().then((result) => {
        setMaturityTestId(
          result?.settings?.find((s) => s.key == 'maturityTestId').value,
        );

        setMaturityTestCheck(result.maturityTestCheck);
        setSettings(result.settings);
      });
    }
    setRendered(true);
  }, [cookies]);

  if (!rendered) {
    if (!cookies.jwt || cookies?.jwt?.message) {
      return <Navigate to={URLS.LOGIN} replace={true} />;
    } else {
      // return <Navigate to={URLS.HOME} replace={true} />;
      // if (!!maturityTestCheck) {
      //   return <Navigate to={URLS.HOME} replace={true} />;
      // } else {
      //   if (currentTestChecker && currentTestChecker != maturityTestId) {
      //     return (
      //       maturityTestId && (
      //         <Navigate to={'/single-test/' + maturityTestId} replace={true} />
      //       )
      //     );
      //   }
      // }
    }
  }
};
