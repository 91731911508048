// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Devider_Devider__XKfCv {\n  margin-top: 30px;\n  margin-bottom: 15px;\n  opacity: 0.5;\n}", "",{"version":3,"sources":["webpack://./src/components/Default/Devider/Devider.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,YAAA;AACJ","sourcesContent":[".Devider {\n    margin-top: 30px;\n    margin-bottom: 15px;\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Devider": "Devider_Devider__XKfCv"
};
export default ___CSS_LOADER_EXPORT___;
